import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, withStyles, theme } from '@material-ui/core/styles';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { SnackBar } from '../../Actions/CommonActions';


  
  const useStyles1 = theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: amber[700],
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: 6,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  });

  

const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        showAlert : state.Common.showAlert,
        alertMessage : state.Common.alertMessage,
        alertVarient : state.Common.alertVarient,        
    }
}

const mapDispatchToProps = dispatch => ({
    SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient))
});

class StaticTextBox extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            
            EditMode:false,
            value:"",
            updated:false
        };
    }

    static getDerivedStateFromProps(props, state){
        if(state.value !== props.value && state.updated === false ){
            return {
                value:props.value,
                updated:true
            }
        }
    }


   handleEdit = () => {
       this.setState({
                        EditMode:true
                      });
   }

   handleApply = () => {

    this.props.onUpdate({field:this.props.field,value:this.state.value})

    this.setState({
        EditMode:false
      });
   }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {
        console.log(this.props);

        return <Fragment>
                    { this.state.EditMode === false ? <input type={this.props.type} name="" style={{backgroundColor:'rgb(249, 249, 249)'}} readOnly={true} value={this.state.value} />:<input type={this.props.type} onChange={(e)=>this.setState({value:e.target.value})}  name="" value={this.state.value} />}
                    { this.state.EditMode === false ? <IconButton size="small" style={{position:'absolute', right:8,top:30}} onClick={()=>this.handleEdit()}><EditIcon/></IconButton>:<IconButton size="small" style={{position:'absolute', right:8,top:30}} onClick={()=>this.handleApply()}><DoneIcon/></IconButton>}
                    
                    
                </Fragment>;
    }
    
}

StaticTextBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };

  StaticTextBox.defaultProps = {
    type: 'text',
  };  

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(useStyles1))(StaticTextBox);
