import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { GetCostEstimation } from '../../Actions/ActivityActions';
import { CartAddItem } from '../../Actions/CartActions';
import { FavoriteAddItem } from '../../Actions/FavoritetActions';
import { SnackBar } from '../../Actions/CommonActions';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share';
import Select from './ControlSelect';

const mapStateToProps = (state) => {
    return {
        estimatedFormatted: state.Activities.activeEstimationFormatted,
        estimated: state.Activities.activeEstimation,
        minBookingDate: state.Activities.minBookingDate,
        user: state.Auth.user,
        cartItems: state.Cart.items,
        selectedLocationId: state.Auth.selectedLocationId,
        selectedDate: state.Auth.selectedDate
    }
}

const mapDispatchToProps = dispatch => ({
    GetCostEstimation: (data) => dispatch(GetCostEstimation(data)),
    CartAddItem: (data) => dispatch(CartAddItem(data)),
    FavoriteAddItem: (ItemID) => dispatch(FavoriteAddItem(ItemID)),
    SnackBar: (show, message, type) => dispatch(SnackBar(show, message, type))
});
class PricingWidget extends Component {

    constructor(props) {
        super(props)


        this.state = {
            pickupDate: new Date(),
            pickupLocation: "",
            numAdults: 0,
            numChidren: 0,
            numInfants: 0,
            amount: null,
            mindateLoaded: false,
        }

    }


    componentDidUpdate = (prevProps) => {

        if (this.state.mindateLoaded === false && this.props.minBookingDate !== null) {

            this.setState({
                pickupDate: this.props.minBookingDate,
                mindateLoaded: true
            })
        }
    }


    updateCosting = () => {

        this.props.GetCostEstimation(
            {
                aid: this.props.activeTour.id,
                pud: this.state.pickupDate === null ? new Date() : this.state.pickupDate,
                pul: this.state.pickupLocation,
                na: this.state.numAdults,
                nc: this.state.numChidren,
                ni: this.state.numInfants
            }
        );
    }

    componentDidUpdate = () => {
        for (var x = 0; x < this.props.activeTour.available_locations.length; x++) {

            if (this.state.pickupLocation === "" && this.props.selectedLocationId !== null) {

                if (this.props.activeTour.available_locations[x].loc_id === this.props.selectedLocationId) {
                    this.setState({
                        pickupLocation: this.props.activeTour.available_locations[x].id
                    })
                }
            }

        }

        if (this.state.pickupDate === null) {

            if (this.props.selectedDate !== null) {
                this.setState({
                    pickupDate: new Date(this.props.selectedDate)
                })
            }

        }
    }


    handleChange = (event, field) => {

        this.setState({ [field]: event.target.value }, () => { this.updateCosting() });
    }

    handleDateChange = (value) => {

        if (this.state.pickupLocation === "") {
            this.props.SnackBar(true, "Please select pick-up loaction first", 'error');
            return;
        }

        this.setState({ pickupDate: value }, () => { this.updateCosting() });
    }

    handleAddItem = (BookNow) => {

        if (this.state.pickupLocation === "") {
            this.props.SnackBar(true, "Please select pick-up loaction", 'error');
            return;
        }

        if (this.state.pickupDate === null) {
            this.props.SnackBar(true, "Please select pick-up date", 'error');
            return;
        }

        if (this.state.numAdults === 0) {
            this.props.SnackBar(true, "Please select number of adults", 'error');
            return;
        }

        if (this.props.estimated !== "" && this.props.estimated !== 0) {
            if (this.props.cartItems.length > 0) {
                for (var x = 0; x < this.props.cartItems.length; x++) {
                    if (this.props.cartItems[x].activity.id === this.props.activeTour.id) {
                        this.props.SnackBar(true, 'This item already exists in your cart', 'error');
                        return false;
                    }
                }
            }
            this.props.CartAddItem({
                user_id: this.props.user !== null ? this.props.user.id : 0,
                from_date: this.state.pickupDate,
                amount: this.props.estimated,
                location_id: this.state.pickupLocation,
                adults: this.state.numAdults,
                children: this.state.numChidren,
                infants: this.state.numInfants,
                activity: {
                    id: this.props.activeTour.id,
                    name: this.props.activeTour.title,
                    image: this.props.activeTour.images[0].url,
                    duration: this.props.activeTour.duration,
                    duration_value: this.props.activeTour.duration_value,
                    pickup_locations: this.props.activeTour.available_locations
                },
                bookNow: BookNow
            });

        } else {
            this.props.SnackBar(true, 'Please fill the booking form correctly', 'error');
        }

    }



    render() {

        return (
            <div className="search_filte">
                <div className="right-filte">

                    <div className="input-field">

                        <Select name="pickUpLocation"
                            value={this.state.pickupLocation}
                            onChange={(e) => this.handleChange(e, 'pickupLocation')}
                            placeholder="Pick-up Location"
                            options={this.props.activeTour.available_locations}
                            optionvalue="id"
                            optionlabel="location_name"
                            style={{ width: '100%' }}
                        />

                    </div>
                    <div style={{ width: '220px' }}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <DatePicker

                                value={this.state.pickupDate}
                                autoOk={true}
                                format="dd/MM/yyyy"
                                inputVariant="standard"
                                onChange={(e) => { this.handleDateChange(e, 'pickupDate') }}
                                minDate={this.props.minBookingDate}
                                style={{ width: "170px" }}
                                id="mui-pickers-date"
                                margin="normal"
                                label='Date'
                            />
                        </MuiPickersUtilsProvider>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoComplete="off"
                                margin="normal"
                                id="mui-pickers-date"
                                style={{ width: "50px" }}
                                label=" "
                                value={this.state.pickupDate}
                                minDate={new Date()}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}

                                autoOk
                            />
                        </MuiPickersUtilsProvider>


                    </div>

                    {this.props.activeTour.adults.length > 0 ? (<div className="drop">
                        <div className="lab">
                            <p>Adults </p>
                            <span>Age {this.props.activeTour.adults_age_from} - {this.props.activeTour.adults_age_to}</span>
                        </div>
                        <div className="sel">
                            <Select name="pickUpLocation"
                                value={this.state.numAdults}
                                onChange={(e) => this.handleChange(e, 'numAdults')}
                                placeholder=""
                                options={this.props.activeTour.adults}
                                optionvalue="id"
                                optionlabel="label"
                                style={{ width: '100%' }} />
                        </div>
                        <div className="clear"></div>
                    </div>) : null}
                    {this.props.activeTour.children.length > 0 ? (<div className="drop">
                        <div className="lab">
                            <p>Children </p>
                            <span>Age {this.props.activeTour.children_age_from} to {this.props.activeTour.children_age_to}</span>
                        </div>
                        <div className="sel">
                            <Select name="pickUpLocation"
                                value={this.state.numChidren}
                                onChange={(e) => this.handleChange(e, 'numChidren')}
                                placeholder=""
                                options={this.props.activeTour.children}
                                optionvalue="id"
                                optionlabel="label"
                                style={{ width: '100%' }} />
                        </div>
                        <div className="clear"></div>
                    </div>) : null}
                    {this.props.activeTour.infants.length > 0 ? (<div className="drop">
                        <div className="lab">
                            <p>Infants </p>
                            <span>Age below {this.props.activeTour.infant_age_to}</span>
                        </div>
                        <div className="sel">
                            <Select name="pickUpLocation"
                                value={this.state.numInfants}
                                onChange={(e) => this.handleChange(e, 'numInfants')}
                                placeholder=""
                                options={this.props.activeTour.infants}
                                optionvalue="id"
                                optionlabel="label"
                                style={{ width: '100%' }} />
                        </div>
                        <div className="clear"></div><br />
                    </div>) : null}

                    {this.props.estimatedFormatted !== "" ? (<div className="total-amunt">
                        <div className="total">
                            <p>Total Amout</p>
                        </div>
                        <div className="usd">
                            <p><span>USD</span>{this.props.estimatedFormatted}</p>
                        </div>
                        <div className="clear"></div>
                    </div>) : null}

                    {this.props.estimated !== "" && this.props.estimated !== 0 ? (

                        <i></i>

                    ) : null}

                    <div>
                        <div>
                            <div className="submit">
                                <i className="waves-effect waves-light btn"><input type="submit" onClick={() => this.handleAddItem(true)} value="Book Now" name="" /></i>
                            </div>
                        </div>

                        <div>

                        </div>
                    </div>

                    <div className="ascf-div">
                        <div className="ascf submit">
                            <span className="material-icons dp48">add_shopping_cart</span>
                            <input type="submit" value="" onClick={() => this.handleAddItem(false)} />
                            <i className="waves-effect waves-light btn"></i>
                        </div>
                        <div className="ascf submit">
                            <span className="material-icons dp48">favorite</span>
                            <input type="submit" onClick={() => { this.props.FavoriteAddItem(this.props.activeTour.id) }} value="" />
                            <i className="waves-effect waves-light btn"></i>
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    <div className="Share">
                        <span>Share Tour with Friends</span>
                        <div id="scioal">
                            <tr>

                                <td style={{ textAlign: 'center' }}><FacebookShareButton
                                    url={window.location.href}
                                    quote={'Facebook'}
                                    className="Demo__some-network__share-button">
                                    <a className="fb-s" ></a>
                                </FacebookShareButton></td>

                                <td style={{ textAlign: 'center' }}>
                                    <TwitterShareButton
                                        url={window.location.href}
                                        quote={'Facebook'}
                                        className="Demo__some-network__share-button">
                                        <a className="tw-s" ></a>
                                    </TwitterShareButton>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <WhatsappShareButton
                                        url={window.location.href}
                                        quote={'Facebook'}
                                        className="Demo__some-network__share-button">
                                        <a className="wht-s" ></a>
                                    </WhatsappShareButton>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <LinkedinShareButton
                                        url={window.location.href}
                                        quote={'Facebook'}
                                        className="Demo__some-network__share-button">
                                        <a className="in-s" ></a>
                                    </LinkedinShareButton>
                                </td>
                            </tr>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PricingWidget.propTypes = {
    activeTour: PropTypes.object.isRequired
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(PricingWidget);
