import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import Header from './Common/Header'
import Footer from './Common/Footer'
import { GetReviews, AddReview } from '../Actions/WebsiteActions'
import AccountNavigation from './Common/AccountNavigation'
import StarRatingComponent from 'react-star-rating-component'
import ReviewModal from './Common/ReviewModal';
import { Helmet } from "react-helmet";
import { SnackBar } from '../Actions/CommonActions'

const mapStateToProps = (state) => {
	return {
		myreviews: state.Website.myreviews
	}
}

const mapDispatchToProps = dispatch => ({
	GetReviews: () => dispatch(GetReviews()),
	AddReview: (bookingId, SupplierID, ActivityID, Rating, FeedBack) => dispatch(AddReview(bookingId, SupplierID, ActivityID, Rating, FeedBack)),
	SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient))
})

class MyReviews extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			activity: null,
			openModal: false,
			rate: 0,
			feedback: "",
			submit: true
		}

		this.props.GetReviews();
	}


	closeModal = () => {
		this.setState({
			openModal: false,
			rate: 0,
			feedback: "",
			submit: true
		});
	}


	rateCount = (count) => {
		this.setState({ rate: count })
	}


	AddReview = () => {

		if (this.state.rate == 0) {
			this.props.SnackBar(true, 'Rate your activity', 'error')
			return false
		} else if (this.state.feedback == "") {
			this.props.SnackBar(true, 'Write your review', 'error')
			return false
		}
		this.props.AddReview(
			this.state.activity.booking_id,
			this.state.activity.activity.user.id,
			this.state.activity.activity.id,
			this.state.rate,
			this.state.feedback
		);
		this.closeModal();
	}
	handlechange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	openModel = (data) => {
		var submit = true
		var rate = 0
		var feedback = ""
		if (data.reviews.id != undefined) {
			submit = false
			rate = data.reviews.rating
			feedback = data.reviews.feedback
		}
		this.setState({
			openModal: true,
			activity: data,
			rate: rate,
			feedback: feedback,
			submit: submit
		})
	}

	render() {
		return <Fragment>
			<Header />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{"My Reviews"}</title>
			</Helmet>
			<section className="body-gry setting-pg">
				<div className="container">
					<div className="row">
						<div className="col l4 m5 s12">
							<AccountNavigation />
						</div>

						<div className="col l8 m7 s12">
							<div className="set-cont">
								<div className="hed-bed">
									<div className="bred-cmp">
										<a href="">Home</a> / <span>Reviews & Ratings</span>
									</div>
									<h1>Reviews & Ratings</h1>
								</div>
								<div className="upcomeing reviews-tbl">
									<table className="upcomeing-tbl">
										{console.log(this.props.myreviews, "myreviews map")}
										{this.props.myreviews.map((item, index) => {
											return <tr className="back-wit">
												<td className="pd-l">
													<h6 className="ae">{item.activity.title}</h6>
													<p className="av">{item.activity.overview.substr(0, 55)}...</p>
												</td>
												<td className="tc">
													<p className="sp">{item.activity.user.supplier_profile.work_name}</p>
												</td>
												<td className="tc">
													<div >
														<StarRatingComponent
															name="rate2"
															editing={false}
															starCount={5}
															value={item.reviews !== null ? item.reviews.rating : 0}
															renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
														/>
													</div>
												</td>
												<td className="tc"><a onClick={() => this.openModel(item)} className="waves-effect waves-light btn modal-trigger">View</a></td>
											</tr>
										})}
									</table>
								</div>

							</div>
						</div>
					</div>
				</div>
				<ReviewModal
					submit={this.state.submit}
					onClose={this.closeModal}
					handlechange={this.handlechange}
					data={this.state.activity}
					rate={this.state.rate}
					feedback={this.state.feedback}
					AddReview={this.AddReview}
					rateCount={this.rateCount}
					open={this.state.openModal} />
			</section>
			<Footer />
		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyReviews);
