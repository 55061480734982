import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading,SnackBar,handleHTTPError} from './CommonActions';


export function fetchLocations(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/locations/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                    dispatch(storeLocations(json));                  
                    dispatch(endLoading());                                                      
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Error loading locations','error'));
        });
        
    }
}


export function storeLocations(locations){
    return{type:'STORE_LOCATIONS',locations:locations};
}