import { applyMiddleware, createStore, combineReducers } from 'redux';

import logger from 'redux-logger'
import thunk from 'redux-thunk';
import promise from "redux-promise";

import reducer from "./Reducers"
import { loadAuthState, saveAuthState } from './Modules/AuthSession';
import { cartSaveState, loadCartState } from './Modules/CartSession';

import throttle from 'lodash/throttle';


const middleware = applyMiddleware(promise, thunk, logger)

const store = createStore(reducer, { Auth: loadAuthState(), Cart: loadCartState() }, middleware);

store.subscribe(throttle(() => {
    saveAuthState({
        Auth: store.getState().Auth
    });

    cartSaveState({
        Cart: store.getState().Cart
    });

}, 1000));
export default store;