import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading} from './CommonActions';


export function fetchCategories(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/categories/get',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',              
            }
        }).then(response => response.json(), error => console.log('An error occurred.', error))               
          .then((json) =>{
                  dispatch(storeCategories(json));                  
                  dispatch(endLoading());
          });
    }
}


export function storeCategories(cats){
    return{type:'STORE_CATEGORIES',categories:cats};
}