import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError,ShowLoadingSpinner} from './CommonActions';
import store from '../store';
import axios from 'axios';



export function MyActivities() {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActivities(json));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeActivities(list) {
    return {
        type: 'STORE_ACTIVITIES',
        list: list
    }
}


export function GetActivity(activityID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/activity?id='+activityID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeActiveActivity(json));
              });
        }).catch((error)=>{
          console.log(error);
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeActiveActivity(act) {
    return {
        type: 'STORE_ACTIVE_ACTIVITY',
        activity: act
    }
}


export function GetCostEstimation(data) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        fetch(API_URL+'/api/activity/cost',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  if(json.status === 'error'){
                    dispatch(SnackBar(true,json.message,'error'));
                    dispatch(updateSelectedEstimation({amount:""}))
                  }else{
                    dispatch(updateSelectedEstimation(json))
                  }
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}


export function UpdateCartItemEstimation(data,itemIndex) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        fetch(API_URL+'/api/activity/cost',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  if(json.status === 'error'){
                    dispatch(SnackBar(true,json.message,'error'));
                    dispatch(updateCartItem({amount:""},data,itemIndex))
                  }else{
                    dispatch(updateCartItem(json,data,itemIndex))
                  }
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function updateCartItem(est,data,index) {
    return {
        type: 'UPDATE_CART_ITEM',
        amount:est.amount,
        amount_formatted:est.amount_formatted,
        data:data,
        index:index
    }
}

export function UpdateAmmendEstimation(data,itemIndex) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        fetch(API_URL+'/api/activity/cost',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  if(json.status === 'error'){
                    dispatch(SnackBar(true,json.message,'error'));
                    dispatch(updateAmmendEstimation({amount:""},data,itemIndex))
                  }else{
                    dispatch(updateAmmendEstimation(json,data,itemIndex))
                  }
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function updateAmmendEstimation(est,data,index) {
    return {
        type: 'BOOKING_AMMENDMENT_UPDATE',
        amount:est.amount,
        amount_formatted:est.amount_formatted,
        data:data,
        index:index
    }
}

export function LockActiveActivity(act) {
    return {
        type: 'LOCK_ACTIVE_ACTIVITY',
    }
}


export function updateSelectedEstimation(est) {
    return {
        type: 'UPDATE_ESTIMATION',
        amount:est.amount,
        amount_formatted:est.amount_formatted,
        min_date:est.min_date,
    }
}


export function updateActivity(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  //dispatch(fetchUser(json));
                  dispatch(SnackBar(true,'Data saved','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }

}


export function uploadPicture(file,aid){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();
        fd.append('file',file,file.name);
        fd.append('aid',aid);

        axios.post(API_URL+'/api/supplier/activities/upload',fd,{
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(GetActivity(aid));
              dispatch(SnackBar(true,'Your profie was updated','success'));
        }).catch((error)=>{
            console.log(error)
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could upload image','error'));
        });
    }
}


export function SearchActivities(keyword,location,tourType,durationMin,durationMax,category,priceMIn,priceMax,deals,featured,page,date) {
    console.log(deals);
    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        dispatch(ShowLoadingSpinner('normalSearchedItemsLoading',true));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?keyword='+keyword+'&location='+location+'&tour_type='+tourType+'&duration_min='+durationMin+'&duration_max='+durationMax+'&category='+category+'&deals='+deals+'&featured='+featured+'&page='+page+'&date='+date,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(ShowLoadingSpinner('normalSearchedItemsLoading',false));
                  dispatch(storeSearchActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeSearchActivities(list) {
    return {
        type: 'STORE_SEARCH_ACTIVITIES',
        list: list
    }
}

export function SearchFeaturedActivities(keyword,location,tourType,durationMin,durationMax,category,priceMIn,priceMax,deals,featured,page,limit,variable) {
    console.log(deals);
    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax

        if(featured === 1){
            dispatch(ShowLoadingSpinner('featuredSearchedItemsLoading',true));
        }

        if(tourType === 1){
            dispatch(ShowLoadingSpinner('mostPopularRoudToursLoading',true));
          }

        if(tourType === 2){
            dispatch(ShowLoadingSpinner('mostPopularDayToursLoading',true));
        }

        fetch(API_URL+'/api/search?keyword='+keyword+'&location='+location+'&tour_type='+tourType+'&duration_min='+durationMin+'&duration_max='+durationMax+'&category='+category+'&deals='+deals+'&featured='+featured+'&page='+page+'&limit='+limit,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  //console.log(json.data);
                  dispatch(storeSearchFeaturedActivities(json.data.results,variable));

                  if(featured === 1){
                    dispatch(ShowLoadingSpinner('featuredSearchedItemsLoading',false));
                  }

                  if(tourType === 1){
                    dispatch(ShowLoadingSpinner('mostPopularRoudToursLoading',false));
                  }

                  if(tourType === 2){
                    dispatch(ShowLoadingSpinner('mostPopularDayToursLoading',false));
                  }
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}



export function storeSearchFeaturedActivities(list,variable) {
    return {
        type: 'STORE_SEARCH_ACTIVITIES_FEATURED',
        list: list,
        var:variable
    }
}



export function FeaturedActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?featured=1&limit=5',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeFeaturedActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeFeaturedActivities(list) {
    return {
        type: 'STORE_FEATURED',
        list: list
    }
}


export function TopSellingActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?limit=4',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeTopSellingActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}


export function storeTopSellingActivities(list) {
    return {
        type: 'STORE_TOP_SELLING',
        list: list
    }
}

export function DealsActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?deals=1&limit=4',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeDealsActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeDealsActivities(list) {
    return {
        type: 'STORE_DEALS',
        list: list
    }
}


export function TopSuggestedActivities() {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?limit=10',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeTopSuggestedActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}


export function storeTopSuggestedActivities(list) {
    return {
        type: 'STORE_SEARCH_SUGGESTIONS',
        locations:[],
        activities:list

    }
}

export function RelatedActivities(cats) {

    return function(dispatch) {
        dispatch(SnackBar(true,'Updating...','info'));
        //+"&price_min="+priceMIn+"&price_max="+priceMax
        fetch(API_URL+'/api/search?category='+cats+'&limit=3',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeRelatedActivities(json.data.results));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }

}

export function storeRelatedActivities(list) {
    return {
        type: 'RELATED_TOURS',
        list: list
    }
}
