import React, { Props, Component, Fragment } from 'react';



class DayTourItinery extends Component {

	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="day-lis">
				
				{this.props.itinery !== null ? this.props.itinery.map((item,i)=>{
					return <div key={"it-"+i} className="day time-ro">
					<div className="lef-day time">
						<span className="num">{item.label}</span>						
					</div>
					{/* <h6>8.30 AM – 9.30 AM: Transportation</h6> */}
					<p>{item.summery}</p>
				</div>;
				}):null}
				
				

			</div>
		);
	}
}

DayTourItinery.defaultProps = {
	home: false,
}


export default (DayTourItinery);