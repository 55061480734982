import React, { Props, Component, Fragment } from 'react';
import SearchResultGridItem from './SearchResultGridItem'


const $ = window.jQuery;



function getTripType(type){

	switch(type){
	  case 1 :{ return "Round tour" }
	  case 2 :{ return "Day Tour" }
	  case 3 :{ return "Activity" }
	  case 4 :{ return "Transport" }
	}
}

function SlideItem(props){
	var item = "<div class='item'>";
		item += "<a href='"+props.full_seo_url+"'>";
		item += "<div class='list-tor top-cal'>";
		item += props.deals !== undefined && props.deals !== null ? ("<span class='off'><p>"+props.deals.subscription.deals.discount+"%</p></span>") : "";
		item += "<img src='"+props.cover_image+"'/>";
		item += "<div>";
		item += "<h5>"+props.title+"</h5>";
		item += "<p>"+getTripType(props.tour_type)+"</p>";
		item += "</div></div></a></div>";

	return item;
}

class HomePageTourList extends Component {

	owlRef = React.createRef();

	constructor(props){
		super(props);

		this.state = {
			itemsLoaded:false
		}
	}


	componentDidUpdate = () =>{

		if(this.props.data !== undefined && this.props.data !== null){

			var slides = "";
			for(var x=0; x < this.props.data.length; x++){
				slides = slides+SlideItem(this.props.data[x]);
			}

			if(slides !=="" && this.state.itemsLoaded === false){

				// $(this.owlRef.current).html(slides);
				$(this.owlRef.current).owlCarousel({
					loop: false,
					margin: 25,
					responsiveClass: true,
					responsive: {
						0: {
							items: 1,
							nav: false,
							dote: true,
							loop: true,

						},
						600: {
							items: 3,
							nav: false,
							dote: true,
							loop: true,
						},
						1000: {
							items: 4,
							loop: false,
							nav: false,
							dote: false,
						}
					}
				});
				//this.mainCarousel.trigger('refresh.owl.carousel');

				this.setState({
					itemsLoaded:true
				})
			}



		}


	}


	componentDidMount(){



	}

	render() {



		return (
			<div className="container">
				<div className="row">
					<div className="col s12">
						<div className="top-sel">
							<h4>{this.props.title}</h4>
						</div>

							<div class="col l12 m12 s12">
								<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlRef} >
									{this.props.mostPopularRoudTours !== null ? this.props.data.map((item, index) => {
										// if (roundTours > 15) return null;
										// if (item.tour_type !== 1) return null;
										// roundTours++;
										return <SearchResultGridItem searchParams={this.props.searchParams} data={item} key={"gv-" + index} />;
									}) : null}
								</div>
							</div>
						{/* <div className={"owl-carousel owl-theme hid-cont owl-top "+ ("auto-carou-"+this.props.id)} ref={this.owlRef} >


						</div> */}
						<div className="view-all">
							<a href={this.props.all}>View All</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default (HomePageTourList);
