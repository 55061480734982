import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';


export function ShowLogin(){
    return{type:'SHOW_LOGIN'};
}

export function HideLogin(){
    return{type:'HIDE_LOGIN'};
}


export function LoginWithSocialMedia(user){
    return function (dispatch){

        dispatch(startLoading());

        fetch(API_URL+'/api/auth/social',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

            body :JSON.stringify(user)
        }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(SnackBar(true,'Login successful','success'));
                    dispatch(storeAuthToken(json));
                    dispatch(fetchUser());
                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Invalid login information','error'));
          });
    }

}



export function validateUser(username,password){
    return function (dispatch){

        dispatch(startLoading());
        dispatch(SnackBar(true,'Loggin you in, please wait','info'));
        fetch(API_URL+'/oauth/token',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

            body :JSON.stringify({
                'client_id' :API_CLIENT_ID,
                'grant_type' :API_GRANT_TYPE,
                'client_secret':API_CLIENT_SECRET,
                'username':username,
                'password': password

            })
        }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(SnackBar(true,'Login successful','success'));
                    dispatch(storeAuthToken(json));
                    dispatch(fetchUser());
                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'Invalid login information','error'));
          });
    }

}
export function storeAuthToken(authToken){
    return{type:'STORE_TOKEN',token:authToken};
}

export function fetchUser(){

    return function (dispatch){
        dispatch(startLoading());
        fetch(API_URL+'/api/account/profile',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' : 'Bearer '+store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{

                  dispatch(storeUser(json));
                  dispatch(endLoading());
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Error fetching data','error'));
        });

    }
}
export function storeUser(user){
    return{type:'STORE_USER',user:user};
}

export function updateUser(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/profile',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchUser(json));
                  dispatch(SnackBar(true,'Your profie was updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update profile','error'));
        });
    }

}

export function storeUsers(list) {
    return {
        type: 'STORE_USERS',
        users: list
    }
}

export function selectUser(user) {
    return {
        type: 'USER_SELECTED',
        user: user
    }
}

export function logoutUser() {
    return {
        type: 'USER_LOGOUT',

    }
}

export function Logout() {
    return function (dispatch) {
        dispatch(logoutUser());
    }

}

export function uploadWorkLogo(file){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();
        fd.append('file',file,file.name)

        axios.post(API_URL+'/api/supplier/profile/logo/work',fd,{
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(fetchUser());
              dispatch(SnackBar(true,'Your profie was updated','success'));
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could upload image','error'));
        });
    }
}

export function uploadPersonalLogo(file){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();
        fd.append('file',file,file.name)

        axios.post(API_URL+'/api/supplier/profile/logo/personal',fd,{
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(fetchUser());
              dispatch(SnackBar(true,'Your profie was updated','success'));
        }).catch((error)=>{
            console.log(error)
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could upload image','error'));
        });
    }
}


export function UpdateField(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/account/profile/field/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({
                field:data.field,
                value:data.value
            }),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(fetchUser());
                  dispatch(SnackBar(true,'Your profie was updated','success'));
              });
        }).catch((error)=>{
          dispatch(endLoading());
          dispatch(SnackBar(true,'Could not update profile','error'));
        });
    }

}

export function SendNewPassword(username){
    return function (dispatch){

        dispatch(startLoading());
        dispatch(SnackBar(true,'Sending you a new password','info'));
        fetch(API_URL+'/api/client/chpwd',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },

            body :JSON.stringify({
                                    'username':username,

                                })
        }).then(handleHTTPError)
          .then((response)=>{
                dispatch(endLoading());
                response.json().then((json)=>{
                    dispatch(SnackBar(true,'We just sent you a new password','success'));

                });
          }).catch((error)=>{
            dispatch(endLoading());
            dispatch(SnackBar(true,'We could not find your account','error'));
          });
    }

}
