import React, { Props, Component, Fragment } from 'react';


import SearchResultGridItem from './SearchResultGridItem';
import { UpdateCartItemEstimation } from '../../Actions/ActivityActions';

import { compose } from 'redux';
import { connect } from 'react-redux';

import Select from './ControlSelect'; 
import { DatePicker,MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';


const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN","JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];



const mapStateToProps = (state) => {
    return {
		searchResults:state.Activities.searchResults,		
    }
  }
  
  const mapDispatchToProps = dispatch => ({	  
	UpdateCartItemEstimation:(data,itemIndex)=>dispatch(UpdateCartItemEstimation(data,itemIndex)),		
  });
class CartItem extends Component {

    constructor(props){
        super(props)

        this.state = {
			view:this.props.view,
			editMode:false,

			pickupDate : new Date(this.props.data.from_date),
            pickupLocation : this.props.data.location_id,
            numAdults:this.props.data.adults,
            numChidren:this.props.data.children,
            numInfants:this.props.data.infants,
            amount:null,
        }
	}
	

	updateCosting = () =>{
        
        this.props.UpdateCartItemEstimation(
            {
                aid: this.props.data.activity.id,
                pud: this.state.pickupDate,
                pul: this.state.pickupLocation,
                na: this.state.numAdults,
                nc:this.state.numChidren,
                ni:this.state.numInfants
			},
			this.props.itemIndex
        );
    }

	
	handleEditMode = () =>{
		this.setState({
			editMode:!this.state.editMode
		})
	}

	handleChange = (event,field) =>{
        
        this.setState({[field]:event.target.value},()=>{this.updateCosting()});
    }
    
    handleDateChange = (value) =>{
        this.setState({pickupDate:value},()=>{this.updateCosting()});
    }

    
	getDuration(measure){        
		switch(measure){
		  case 1 :{ return "Days" }
		  case 2 :{ return "Hours" }
		  case 3 :{ return "Minutes" }
		}
	  }
    

    render() {
        return (
            <div>
                <div className="lfsh-row">
									<div className="sh-day">
										<span className="mnt">{ monthNames[new Date(this.props.data.from_date).getMonth()]}</span>
										<span className="dy">{new Date(this.props.data.from_date).getDate()}</span>
										<span className="yer">{new Date(this.props.data.from_date).getFullYear()}</span>
									</div>
									<div className="sh-cont">
										<div className="sh-img">

										{this.props.data.activity_images !== null ? (
											<img src={this.props.data.activity.image} />
										):null}

											
										</div>
										<div className="sh-cn">
											<h6>{this.props.data.activity.name}</h6>
											<span>{this.props.data.adults} Adults {this.props.data.children !== null ? ( this.props.data.children+' Children' ):null} {this.props.data.children !== null ? ( this.props.data.infants+' Infants' ):null}   </span>
										</div>
										<div className="clear"></div>
										<div className="sh-data">
											{/* <span className="free">Free cancellation before May 16, 2019</span> */}
											<span className="dys">{this.props.data.activity.duration_value} {this.getDuration(this.props.data.activity.duration)} (Approx.)</span>
										</div>
									</div>
									<div className="sh-pris">
										<p>USD {this.props.data.amount}</p>
										<div>
											<span className="eit" onClick={()=>this.handleEditMode()}></span>
											<a className="cls" onClick={()=>{this.props.onRemoveItem(this.props.itemIndex)}}></a>
										</div>
									</div>
									<div className="clear"></div>
									<div className="off-form" style={{maxHeight: this.state.editMode === true ? 400:0 }}>
										<div className="right-filte">
											<div>
												<div className="row">
													<div className="col l6 m6 s12">
														<p>Travel date <span id="mendatory">*</span></p>
														<div className="date-pick">
														<MuiPickersUtilsProvider utils={DateFnsUtils}>           
                                
															<DatePicker 
																
																value={this.state.pickupDate}
																autoOk={true}     
																format="dd/MM/yyyy"
																inputVariant="standard"
																onChange={(e)=>{this.handleDateChange(e,'pickupDate')}}

																style={{width:"170px"}}    
																id="mui-pickers-date"                         
																margin="normal"																
																		/>
														</MuiPickersUtilsProvider>
														</div>
													</div>
													<div className="col l6 m6 s12">
														<p>Pickup location<span id="mendatory">*</span> </p>
														<div className="location">
														<Select name="pickUpLocation" 
																value={this.state.pickupLocation}
																onChange={(e)=>this.handleChange(e,'pickupLocation')}
																placeholder="Pick-up Location"
																options={this.props.data.activity.pickup_locations}
																optionvalue="id"
																optionlabel="location_name"
																style={{width:'100%'}} 
																/>
												        </div>
											        </div>
										        </div>
										        <div className="row">
										        	<div className="col l4 m4 s12">
												        <div className="drop">
												        	<div className="lab">
												        		<p>Adult<span id="mendatory">*</span> </p>
												        		{/* <span>(Age 12 & Above)</span> */}
												        	</div>
												        	<div className="sel">
															<Select name="pickUpLocation" 
																	value={this.state.numAdults}
																	onChange={(e)=>this.handleChange(e,'numAdults')}
																	placeholder=""
																	options={[{id:0,label:"0"},{id:1,label:"1"},{id:2,label:"2"},{id:3,label:"3"},{id:4,label:"4"}]}
																	optionvalue="id"
																	optionlabel="label"
																	style={{width:'100%'}} />
												        	</div>
												        	<div className="clear"></div>
												        </div>
											        </div>
											        <div className="col l4 m4 s12">
												        <div className="drop">
												        	<div className="lab">
												        		<p>Child </p>
												        		{/* <span>(Age 05 to 11)</span> */}
												        	</div>
												        	<div className="sel">
															<Select name="pickUpLocation" 
																	value={this.state.numChidren}
																	onChange={(e)=>this.handleChange(e,'numChidren')}
																	placeholder=""
																	options={[{id:0,label:"0"},{id:1,label:"1"},{id:2,label:"2"},{id:3,label:"3"},{id:4,label:"4"}]}
																	optionvalue="id"
																	optionlabel="label"
																	style={{width:'100%'}} />
												        	</div>
												        	<div className="clear"></div>
												        </div>
											        </div>
											        <div className="col l4 m4 s12">
												        <div className="drop">
												        	<div className="lab">
												        		<p>Infant </p>
												        		{/* <span>(Age below 04)</span> */}
												        	</div>
												        	<div className="sel">
															<Select name="pickUpLocation" 
                                            value={this.state.numInfants}
                                            onChange={(e)=>this.handleChange(e,'numChidren')}
                                            placeholder=""
                                            options={[{id:0,label:"0"},{id:1,label:"1"},{id:2,label:"2"},{id:3,label:"3"},{id:4,label:"4"}]}
                                            optionvalue="id"
                                            optionlabel="label"
                                            style={{width:'100%'}} />
												        	</div>
												        	<div className="clear"></div>
												        </div>
											        </div>
										        </div>
										        <div className="row">
										        	<div className="col s12">
												        <div className="total-amunt">
												        	<div className="total">
												        		<p>Total Amout</p>
												        	</div>
												        	<div className="usd">
												        		<p><span>USD</span> {this.props.data.amount}</p>
												        	</div>
												        	<div className="clear"></div>
												        </div>

												        <div className="submit">
												        	<i className="waves-effect waves-light btn waves-input-wrapper">
															<input type="submit" onClick={()=>this.handleEditMode()} className="waves-effect waves-light btn" value="Done" name="" />
															</i>
															
												        </div>
											        </div>
										        </div>
											</div>
										</div>
									</div>
								</div>
            </div>
        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(CartItem);