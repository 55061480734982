import React, { Component, Fragment } from 'react'
import SearchResultLIstViewItem from './Common/SearchResultLIstViewItem'
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { getSearchSuggestions, GetReviews } from '../Actions/WebsiteActions'
import { SnackBar } from '../Actions/CommonActions'
import AccountNavigation from './Common/AccountNavigation'
import { FeaturedActivities } from '../Actions/ActivityActions'
import StarRatingComponent from 'react-star-rating-component'
import ReviewModal from './Common/ReviewModal'
import { Helmet } from "react-helmet"


const mapStateToProps = (state) => {
	return {
		searchResultsLocations: state.Website.searchResultsLocations,
		searchResultsActivities: state.Website.searchResultsActivities,
		featuredTours: state.Website.featuredTours,
		user: state.Auth.user,
		myreviews: state.Website.myreviews
	}
}

const mapDispatchToProps = dispatch => ({
	getSearchSuggestions: (keyword) => dispatch(getSearchSuggestions(keyword)),
	SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
	FeaturedActivities: () => dispatch(FeaturedActivities()),
	GetReviews: () => dispatch(GetReviews())
});

class MyAccount extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			activity: null,
			openModal: false,
			rate: 0,
			feedback: "",
			submit: true
		}

		this.props.GetReviews();
		this.props.FeaturedActivities();
	}


	closeModal = () => {
		this.setState({
			openModal: false,
			rate: 0,
			feedback: "",
			submit: true
		});
	}

	getDuration(measure) {
		switch (measure) {
			case 1: { return "Days" }
			case 2: { return "Hours" }
			case 3: { return "Minutes" }
		}
	}

	handleChange = (event, field) => {

		this.setState({
			[field]: event.target.value
		}, () => {

			this.props.getSearchSuggestions(this.state.searchField);

		});
	}

	rateCount = (count) => {
		this.setState({ rate: count })
	}


	AddReview = () => {

		if (this.state.rate == 0) {
			this.props.SnackBar(true, 'Rate your activity', 'error')
			return false
		} else if (this.state.rate == "") {
			this.props.SnackBar(true, 'Write your review', 'error')
			return false
		}
		this.props.AddReview(
			this.state.activity.booking_id,
			this.state.activity.activity.user.id,
			this.state.activity.activity.id,
			this.state.rate,
			this.state.feedback
		);
		this.closeModal();
	}
	handlechangeReview = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}


	render() {
		return <Fragment>
			<Header />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{"Dashboard"}</title>
			</Helmet>
			<section class="body-gry setting-pg">
				<div class="container">
					<div class="row">
						<div class="col l4 m5 s12">
							<AccountNavigation />
						</div>

						<div class="col l8 m7 s12">
							<div class="set-cont">
								<div class="hed-bed">
									<div class="bred-cmp">
										<a href="">Dashboard</a>
									</div>
									<h1>Hello, {this.props.user.first_name} {this.props.user.last_name}</h1>
								</div>
								<div class="set-act-div set-my-account">
									{console.log(this.props.myreviews, "sajith activity")}
									{this.props.myreviews !== null && this.props.myreviews.length > 0 ? (
										<div class="hiyla">
											<label>How is your last Activity ? </label>
											<span class="ret rev-st">
												<StarRatingComponent
													name="rate2"
													editing={false}
													starCount={5}
													value={this.props.myreviews[this.props.myreviews.length - 1].reviews.rating}
													renderStarIcon={() => <i style={{ fontSize: 36, fontStyle: 'normal' }} >★</i>}
												/>
											</span>
											<div>
												{this.props.myreviews[this.props.myreviews.length - 1].reviews.id === undefined || this.props.myreviews[this.props.myreviews.length - 1].reviews.rating === 0 ? (<a class="waves-effect waves-light btn" onClick={() => { this.setState({ openModal: true, activity: this.props.myreviews[this.props.myreviews.length - 1] }) }} >Review Now</a>) : <div style={{ height: 25 }} ></div>}

											</div>
										</div>
									) : null}

									{this.props.myreviews !== null && this.props.myreviews.length > 0 ? (
										<div class="recent">
											<div class="rs-img">
												<img src={this.props.myreviews[this.props.myreviews.length - 1].activity.cover_image} />
												<span>Recent</span>
											</div>
											<div class="rs-dit">
												<h6>{this.props.myreviews[this.props.myreviews.length - 1].activity.title}</h6>
												<div class="lc-rw">
													<p><span>Duration</span>{this.props.myreviews[this.props.myreviews.length - 1].activity.duration}&nbsp;{this.getDuration(this.props.myreviews[this.props.myreviews.length - 1].activity.duration)}</p>
												</div>
												<div class="pr-rw">
													<span>Price</span>
													<p>$1,076</p>
												</div>
											</div>
										</div>
									) : null}


									<div class="clear"></div>
									<div class="lis-hed">
										<h6>We would Suggest</h6>
									</div>
									{this.props.featuredTours !== null ? this.props.featuredTours.map((item, index) => {
										return <SearchResultLIstViewItem data={item} key={'lv-' + index} />;
									}) : null}
									<div class="hr"></div>

								</div>

							</div>
						</div>
					</div>
				</div>

				<ReviewModal
					open={this.state.openModal}
					data={this.state.activity}
					onClose={this.closeModal}
					submit={this.state.submit}
					rate={this.state.rate}
					feedback={this.state.feedback}
					handlechange={this.handlechangeReview}
					AddReview={this.AddReview}
					rateCount={this.rateCount} />
				{/* <ReviewModal 
						onClose={this.closeModal}
						data={this.state.activity}
						open={this.state.openModal} /> */}

			</section>
			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAccount);
