import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import {showInquire,fetchInquare,storeInquiry,SnackBar} from '../../Actions/CommonActions';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { ConfimEmail } from '../../Actions/CommonActions';
import { MakeBooking } from '../../Actions/WebsiteActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
      
        show_inquare:state.Common.show_inquare, 
        inquaries:state.Common.fetchInquare,  
        snackbar: state.Common.snackbar,            
    }
}

const mapDispatchToProps = dispatch => ({

  showInquire:(data)=>dispatch(showInquire(data)),
  fetchInquare:(name,email,phone,message)=>dispatch(fetchInquare(name,email,phone,message)),
  SnackBar:(show,message,varient)=>dispatch(SnackBar(show,message,varient))
});

class InduiryPopup extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showAlert : true,
            alertMessage : null,
            alertVarient : 'info',
            name:null,
            email:null,
            phone:null,
            message:null,
        };
        // this.props.fetchInquare(this.state.name,this.state.email,this.state.phone,this.state.message);
        // this.props.SnackBar(false);
    }

    handleConfirmaton = () =>{
      
    
      this.props.showInquire(true);
    }
  //   selectkey = (e) =>{
  //     this.setState({
  //       searchkey:e.target.value
  //     },()=>this.searchBookings())
  // }

    sentInquare = ()=>{
      
      if(this.state.name === null || this.state.name === "" )
      {

        this.props.SnackBar(true,'Name field is mandatory','error');
        return false;
       
      }

      if( this.state.email===null || this.state.email === "" )
      {
        this.props.SnackBar(true,'Email field is mandatory','error');
        return false;
       
      }
      
      if( this.state.phone ===null || this.state.phone === "" )
      {
        this.props.SnackBar(true,'Phone field is mandatory','error');
        return false;
       
      }
      if( this.state.message ===null || this.state.message === "")
      {
        this.props.SnackBar(true,'Message field is mandatory','error');
        return false;
       
      }
      
      this.props.fetchInquare(this.state.name,this.state.email,this.state.phone,this.state.message);
        // return <Redirect to="/" />;  
      


   
  }


    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    handleChange = ( event, name ) => {
      this.setState({
        [name]: event.target.value,
      });
  
    };

    handleInquary=()=>{

      this.props.fetchInquare(this.state);

    }

    render() {

       
        return (
            <div>             
              <Dialog
      
        open={this.props.show_inquare}
       //open={true}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Inquire Now "}</DialogTitle>
        <DialogContent>
         
            <div >
            <div>
                <label htmlFor="">Name</label>
                <input type="text" value={this.state.name}   onChange={(e)=>{this.handleChange(e,'name')}} required   name="name" id="name"/>
            </div>
            <div>
                <label htmlFor="">Email</label>
                <input type="email" value={this.state.email}   onChange={(e)=>{this.handleChange(e,'email')}} required name="email" id="email"/>
            </div>
            <div>
                <label htmlFor=""> Phone </label>
                <input type="text" value={this.state.phone}  onChange={(e)=>{this.handleChange(e,'phone')}} required name="phone_no" id="phone_no"/>
            </div>
            <div>
                <label htmlFor=""> Message  </label>
                <input type="text" value={this.state.message}   onChange={(e)=>{this.handleChange(e,'message')}} required  name="message" id="message"/>
            </div>
            </div>

        </DialogContent>
        <DialogActions>

         
          <Button  onClick={()=>{this.props.showInquire(false)}}  color="primary">
            Cancel
          </Button>
          <Button type='submit'  onClick={()=>{ this.sentInquare(); }}    color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(InduiryPopup);
