import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { registerAccount } from '../Actions/WebsiteActions';
import { Redirect } from 'react-router-dom'

import Header from './Common/Header';
import Footer from './Common/Footer';

import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Helmet} from "react-helmet";



const mapStateToProps = (state) => {
    return {
        clientSignUpComplete: state.Website.clientSignUpComplete,
        loggedIn: state.Auth.loggedIn
    }
}

const mapDispatchToProps = dispatch => ({
    createAccount: (data, UserType) => dispatch(registerAccount(data, UserType))
});
class SupplierSignUp extends Component {

    constructor(props) {
        super(props);


        this.state = {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            confim_password: null

        }
    }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,

        ],
        callbacks: {
            signInSuccess: () => false
        }
    }

    handleUpdate = (event, field) => {
        this.setState({
            [field]: event.target.value
        })
    }

    doRegistration = () => {
        this.props.createAccount(this.state, 3);
    }


    componentDidMount() {
        window.scrollTo(0, 0)
    }



    componentDidMount = () => {

        firebase.auth().onAuthStateChanged(user => {
            if (user !== null && this.props.loggedIn === false) {
                this.props.LoginWithSocialMedia(user);
            }

        })
    }

    render() {
        return <Fragment>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Account Sign-up</title>
            </Helmet>
            <section className="gry-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                <h1>Sign up with Aktivitar</h1>
                                <p>Log in to add things to your wishlist and access your bookings from any device</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cont-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">


                                <div className="inp">
                                    <label>First name</label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'first_name'); }} />
                                </div>
                                <div className="inp">
                                    <label>Last Name</label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'last_name'); }} />
                                </div>
                                <div className="inp">
                                    <label> Email</label>
                                    <input type="text" onChange={(e) => { this.handleUpdate(e, 'email'); }} />
                                </div>
                                <div className="inp">
                                    <label>Password</label>
                                    <input type="password" onChange={(e) => { this.handleUpdate(e, 'password'); }} />
                                </div>
                                <div className="inp">
                                    <label>Confirm password</label>
                                    <input type="password" onChange={(e) => { this.handleUpdate(e, 'confim_password'); }} />
                                </div>

                                <div className="forgot">
                                    <p>
                                        <label>
                                            <input type="checkbox" />
                                            <span>I'd like to receive special offers, inspiration, tips and other updates from Aktivitar.</span>
                                        </label>
                                    </p>
                                </div>
                                <div className="log-btn">
                                    <i className="waves-effect waves-light btn">
                                        <input type="submit" onClick={this.doRegistration} value="Create an Account" />
                                    </i>

                                </div>
                                <div className="sinwith">
                                    <p> Or Sign in with </p>
                                    <div>
                                        <StyledFirebaseAuth
                                            uiConfig={this.uiConfig}
                                            firebaseAuth={firebase.auth()}
                                        />
                                        <div className="clear"></div>
                                    </div>
                                </div>
                                <div className="bca">
                                    By creating an account, you agree to Aktivitar's <a href="">Terms & Conditions and Private Policy.</a>
                                </div>
                                <div className="hv-acunt">
                                    <p>Already have an account? <a href="">Log in</a></p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {this.props.clientSignUpComplete ? <Redirect to="/login"></Redirect> : null}
            {this.props.loggedIn ? <Redirect to="/account"></Redirect> : null}
            <Footer />

        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SupplierSignUp);