import React, { Props, Component, Fragment } from 'react';


import SearchResultGridItem from './SearchResultGridItem';
import { UpdateAmmendEstimation } from '../../Actions/ActivityActions';

import { compose } from 'redux';
import { connect } from 'react-redux';

import Select from './ControlSelect';
import { DatePicker,MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';


const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN","JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];



const mapStateToProps = (state) => {
    return {
		searchResults:state.Activities.searchResults,
    }
  }

  const mapDispatchToProps = dispatch => ({
	UpdateAmmendEstimation:(data,itemIndex)=>dispatch(UpdateAmmendEstimation(data,itemIndex)),
  });
class AmmendmentItem extends Component {

    constructor(props){
        super(props)

        this.state = {
			      view:this.props.view,
			      editMode:false,
			      pickupDate : new Date(this.props.data.booking.start_date),
            pickupLocation : this.props.data.booking.location_id,
            numAdults:this.props.data.booking.num_adults,
            numChidren:this.props.data.booking.num_children,
            numInfants:this.props.data.booking.num_infants,
            amount:this.props.data.amount,
            amountFortmatted:this.props.data.amountFormatted,
        }
	}


	updateCosting = () =>{
        this.props.dataUpdate({
            booking:this.props.data.booking,
            ammendData:{
                aid: this.props.data.booking.activity.id,
                pud: this.state.pickupDate,
                pul: this.state.pickupLocation,
                na: this.state.numAdults,
                nc:this.state.numChidren,
                ni:this.state.numInfants,
                amount:this.props.data.amount
			}
        })
        this.props.UpdateAmmendEstimation(
            {
                aid: this.props.data.booking.activity.id,
                pud: this.state.pickupDate,
                pul: this.state.pickupLocation,
                na: this.state.numAdults,
                nc:this.state.numChidren,
                ni:this.state.numInfants
			},
			this.props.itemIndex
        );
    }


	handleEditMode = () =>{
		this.setState({
			editMode:!this.state.editMode
		})
	}

	handleChange = (event,field) =>{

        this.setState({[field]:event.target.value},()=>{this.updateCosting()});
    }

    handleDateChange = (value) =>{
        this.setState({pickupDate:value},()=>{this.updateCosting()});
    }


	getDuration(measure){
		switch(measure){
		  case 1 :{ return "Days" }
		  case 2 :{ return "Hours" }
		  case 3 :{ return "Minutes" }
		}
	  }


    render() {
        return (
          <div>
            <div className="row">
              <div className="col l6 m6 s12">
                <p>Travel date</p>
                <div className="date-pick">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                  <DatePicker
                    className="customDatePickerAmmend"
                    value={this.state.pickupDate}
                    autoOk={true}
                    format="dd/MM/yyyy"
                    inputVariant="standard"
                    onChange={(e)=>{this.handleDateChange(e,'pickupDate')}}

                    style={{width:"170px !important"}}
                    id="mui-pickers-date"
                    margin="normal"
                        />
                </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className="col l6 m6 s12">
                <p>Pickup location </p>
                <div className="location">
                              <Select name="pickUpLocation"
                                  value={this.state.pickupLocation}
                                  onChange={(e)=>this.handleChange(e,'pickupLocation')}
                                  placeholder="Pick-up Location"
                                  options={this.props.data.booking.activity.available_locations}
                                  optionvalue="id"
                                  optionlabel="location_name"
                                  style={{width:'100%'}}
                                  />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col l4 m4 s12">
                    <div className="drop">


                      {this.props.data.booking.activity.include_adults === 1 ? (

                        <Fragment>
                        <div className="lab">
                        <p>Adult </p>
                        {/* <span>(Age 12 & Above)</span> */}
                      </div>
                      <div className="sel">
                         <Select name="pickUpLocation"
                            value={this.state.numAdults}
                            onChange={(e)=>this.handleChange(e,'numAdults')}
                            placeholder=""
                            options={this.props.data.booking.activity.adults}
                            optionvalue="id"
                            optionlabel="label"
                            style={{width:'100%'}} />
                      </div>
                        </Fragment>
                      ):null}

                      <div className="clear"></div>
                    </div>
                  </div>
                  <div className="col l4 m4 s12">
                    <div className="drop">
                    {this.props.data.booking.activity.include_children === 1 ? (
                      <Fragment>
                      <div className="lab">
                        <p>Child </p>
                        {/* <span>(Age 05 to 11)</span> */}
                      </div>
                      <div className="sel">
                                  <Select name="pickUpLocation"
                                      value={this.state.numChidren}
                                      onChange={(e)=>this.handleChange(e,'numChidren')}
                                      placeholder=""
                                      options={this.props.data.booking.activity.children}
                                      optionvalue="id"
                                      optionlabel="label"
                                      style={{width:'100%'}} />
                      </div>
                      </Fragment>
                    ):null}

                      <div className="clear"></div>
                    </div>
                  </div>
                  <div className="col l4 m4 s12">
                    <div className="drop">
                    {this.props.data.booking.activity.include_infants === 1 ? (
                      <Fragment>
                      <div className="lab">
                        <p>Infant </p>
                        {/* <span>(Age below 04)</span> */}
                      </div>
                      <div className="sel">
                          <Select name="pickUpLocation"
                                        value={this.state.numInfants}
                                        onChange={(e)=>this.handleChange(e,'numChidren')}
                                        placeholder=""
                                        options={this.props.data.booking.activity.infants}
                                        optionvalue="id"
                                        optionlabel="label"
                                        style={{width:'100%'}} />
                      </div>
                      </Fragment>
                    ):null}

                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col s12">
                    <div className="total-amunt">
                      <div className="total">
                        <p>Total Amout</p>
                      </div>
                      <div className="usd">
                        <p><span>USD</span> {this.props.data.amountFormatted}</p>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
          </div>
        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(AmmendmentItem);
