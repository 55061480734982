import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';

import {Redirect} from 'react-router-dom'

import { registerAccount } from '../Actions/WebsiteActions';

import Header from './Common/Header';
import Footer from './Common/Footer'; 




const mapStateToProps = (state) => {
    return {
        supplierSignUpComplete:state.Website.supplierSignUpComplete
    }
  }
  
  const mapDispatchToProps = dispatch => ({
        createAccount:(data,UserType)=>dispatch(registerAccount(data,UserType))
  });
class SupplierSignUp extends Component {

    constructor(props){
        super(props);


        this.state = {
                        first_name:null,
                        last_name:null,
                        email:null,
                        password:null,
                        confim_password:null

                     }

                         }

    handleUpdate = (event,field) =>{
        this.setState({
            [field]:event.target.value
        })
    }

    doRegistration = () =>{
        this.props.createAccount(this.state,2);       
    }

    static getDerivedStateFromProps(props, state) {
        if(props.supplierSignUpComplete){
           
        }
    }

    componentDidMount() {
        window.scrollTo(0,0)
      }

  

    render() {
        return <Fragment>
            <Header />
            <section className="gry-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                <h1>Sign up with Aktivitar</h1>
                                <p>Log in to add things to your wishlist and access your bookings from any device</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cont-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                
                                    
                                    <div className="inp">
                                        <label>First name</label>
                                        <input type="text" onChange={(e)=>{this.handleUpdate(e,'first_name');}} name="" />
                                    </div>
                                    <div className="inp">
                                        <label>Last name</label>
                                        <input type="text" onChange={(e)=>{this.handleUpdate(e,'last_name');}} name="" />
                                    </div>                                    
                                    <div className="inp">
                                        <label> Email</label>
                                        <input type="text" onChange={(e)=>{this.handleUpdate(e,'email');}} name="" />
                                    </div>
                                    <div className="inp">
                                        <label>Password</label>
                                        <input type="password" name="" onChange={(e)=>{this.handleUpdate(e,'password');}} />
                                    </div>
                                    <div className="inp">
                                        <label>Confirm password</label>
                                        <input type="password" name="" onChange={(e)=>{this.handleUpdate(e,'confim_password');}} />
                                    </div>

                                    <div className="forgot">
                                        <p>
                                            <label>
                                                <input type="checkbox" />
                                                <span>I'd like to receive special offers, inspiration, tips and other updates from Aktivitar.</span>
                                            </label>
                                        </p>
                                    </div>
                                    <div className="log-btn">
                                        <i className="waves-effect waves-light btn waves-input-wrapper"><input type="submit" onClick={this.doRegistration} style={{cursor:'pointer'}}  value="Create an Account" /></i>                                   

                                        
                                    </div>
                                    
                                    <div className="bca">
                                        By creating an account, you agree to Aktivitar's <a href="">Terms & Conditions and Private Policy.</a>
                                    </div>
                                    <div className="hv-acunt">
                                        <p>Already have an account? <a href="https://supplier.aktivitar.com/">Log in</a></p>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {this.props.supplierSignUpComplete ? <Redirect to="/signup/complete"></Redirect>:null}
            <Footer />

        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SupplierSignUp);