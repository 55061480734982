export default function reducer(state={
    list: [],
    total:0,
    totalFormatted:"",
    bookNow:false              
    
},action){
switch(action.type){
    case "STORE_FAVORITE_ITEMS":{
        return{...state,list:action.list,total:action.total,totalFormatted:action.totalFormatted}
    }       
    case "LOADIND_END":{ 
        return{...state,loading:false}
    }
    case "UPDATE_TOUR":{
        return {...state,activeTour:action.tour}
    } 
    case "UPDATE_ESTIMATION":{
        return {...state,activeEstimation:action.amount}
    }   
    case "BOOK_NOW":{
        return {...state,bookNow:action.bookNow}
    }
}
return state
}