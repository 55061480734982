import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { ConfimEmail } from '../../Actions/CommonActions';
import { MakeBooking } from '../../Actions/WebsiteActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


  
  

   


const mapStateToProps = (state) => {
    return {       
        emailConfirmation : state.Common.emailConfirmation, 
        emailConfirmData : state.Common.emailConfirmData, 
        onEmailConfirm : state.Common.onEmailConfirm,               
    }
}

const mapDispatchToProps = dispatch => ({
  ConfimEmail : (show,data,onConfirm) => dispatch(ConfimEmail(show,data,onConfirm))
});

class EmailConfirmation extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showAlert : true,
            alertMessage : null,
            alertVarient : 'info',
        };
    }

    handleConfirmaton = () =>{
      this.props.onEmailConfirm(this.props.emailConfirmData);
      this.props.ConfimEmail(false,null,null); 
    }

    handleClose = ()=> {        
       
        this.props.SnackBar(false,null,this.props.alertVarient);
    }

    render() {

        return (
            <div>             
              <Dialog
        open={this.props.emailConfirmation}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>{ this.props.ConfimEmail(false,null,null);}}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Confirm email address"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please confirm your email address <b>{this.props.emailConfirmData !== null ? this.props.emailConfirmData[0].booking_object.contact_email : null }</b>. If you are not a member, don't worry we will create an account for you and send you the password.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{ this.props.ConfimEmail(false,null,null);}}  color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{this.handleConfirmaton();}}  color="primary">
            Yes, Proceed
          </Button>
        </DialogActions>
      </Dialog>
              </div>);
    }
    
}


export default compose(connect(mapStateToProps, mapDispatchToProps))(EmailConfirmation);
