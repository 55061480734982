import React, { Props, Component, Fragment } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';



import SearchResultGridItem from './SearchResultGridItem';

import { compose } from 'redux';
import { connect } from 'react-redux';


const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN","JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const mapStateToProps = (state) => {
	return {
		searchResults: state.Activities.searchResults,
	}
}

const mapDispatchToProps = dispatch => ({

});
class CheckoutItem extends Component {

	constructor(props) {
		super(props)

		this.state = {
			view: this.props.view
		}
	}

	componentDidMount() {
		console.log(window);
	}


	getDuration(measure) {
		switch (measure) {
			case 1: { return "Days" }
			case 2: { return "Hours" }
			case 3: { return "Minutes" }
		}
	}


	render() {
		return (
			<div>

				<div className="chik-main">
					<div className="lef-shp">
						<div className="lfsh-row">
							<div className="sh-day">
									<span className="mnt">{ monthNames[new Date(this.props.data.from_date).getMonth()]}</span>
									<span className="dy">{new Date(this.props.data.from_date).getDate()}</span>
									<span className="yer">{new Date(this.props.data.from_date).getFullYear()}</span>
							</div>
							<div className="sh-cont">
								<div className="sh-cn">
									<h6>{this.props.data.activity.name}</h6>
									<span>{this.props.data.adults} Adults {this.props.data.children !== null ? (this.props.data.children + ' Children') : null} {this.props.data.infants !== null ? (this.props.data.infants + ' Infants') : null}</span>
								</div>
								<div className="clear"></div>
							</div>
							<div className="sh-pris">
								<p>USD {this.props.data.amount}</p>
							</div>
							<div className="clear"></div>
						</div>
					</div>
					<div className="ldt">
						<h4>Lead Traveler Details</h4>
						<div>
							<div>
								<div className="row">
								<div className="col l6 m6 s12">
									<input type="text" placeholder="First Name*" onChange={(e)=>{ this.props.onTravelerChange(e,'firstname',this.props.cartItemIndex,0)}} name="" />
								</div>
								<div className="col l6 m6 s12">
									<input type="text" placeholder="Last Name*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'lastname',this.props.cartItemIndex,0)}} />
								</div>
							</div>
								<div className="row">
								<div className="col l6 m6 s12">
									<input type="text" placeholder="Email*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'email',this.props.cartItemIndex,0)}} />
								</div>
								<div className="col l6 m6 s12">
									<input type="text" placeholder="Phone Nnumer*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'phone',this.props.cartItemIndex,0)}} />
								</div>
							</div>
							</div>


							<div className="hr"></div>
							
							{ this.props.data.booking_object.traveller.map((item,index)=>{
								if(index==0){ return null; }
								return <div>
								<div className="row">
									<label>Traveler #{index+1}</label>
									<div className="col l6 m6 s12">
										<input type="text" placeholder="First Name*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'firstname',this.props.cartItemIndex,index)}} />
									</div>
									<div className="col l6 m6 s12">
										<input type="text" placeholder="Last Name*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'lastname',this.props.cartItemIndex,index)}}/>
									</div>
								</div>
								<div className="row">
									<div className="col l6 m6 s12">
										<input type="text" placeholder="Email*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'email',this.props.cartItemIndex,index)}} />
									</div>
									<div className="col l6 m6 s12">
										<input type="text" placeholder="Phone Nnumer*" name="" onChange={(e)=>{ this.props.onTravelerChange(e,'phone',this.props.cartItemIndex,index)}} />
								</div>
							</div>
						</div>;						
						
							})}
						</div>
					</div>
					<div className="irbt">
						<h4>Information requested by tour operator</h4>
						<p>
							<span>Pickup from </span>

							<RadioGroup aria-label="position" name="position" value={this.props.data.booking_object.pickup_location} onChange={(e)=>{this.props.onPickupChnage(e,'pickup_location',this.props.cartItemIndex)}} row>
								<FormControlLabel
								
								value="airport"
								control={<Radio style={{
									color: '#43a047',
									'&$checked': {
									  color: '#43a047',
									},
								  }} className="with-gap" color="primary" />}
								label="Aiport"
								labelPlacement="end"
								/>		
								<FormControlLabel
								className="with-gap"
								value="hotel"
								control={<Radio className="with-gap" color="primary" />}
								label="Hotel"
								labelPlacement="end"
								/>
							</RadioGroup>						
						</p>

						<div className={"frm-fild hotel "+(this.props.data.booking_object.pickup_location === 'hotel' ? 'act':null)}>
							<div className="row">
								<div className="col l12 m12 s12">
									<label>Hotel Name</label>
									<input type="text" name="" onChange={(e)=>{this.props.onPickupChnage(e,'hotel_name',this.props.cartItemIndex)}} />
								</div>
								<div className="col l12 m12 s12">
									<label>Hotel Address</label>
									<textarea onChange={(e)=>{this.props.onPickupChnage(e,'hotel_address',this.props.cartItemIndex)}}></textarea>
								</div>
								<div className="col l12 m12 s12">
									<label>Special Note</label>
									<textarea onChange={(e)=>{this.props.onPickupChnage(e,'remarks',this.props.cartItemIndex)}}></textarea>
								</div>
							</div>
						</div>
						<div className={"frm-fild "+(this.props.data.booking_object.pickup_location === 'airport' ? 'act':null)}>
							<div className="row">
								<div className="col l16 m6 s12">
									<label>Flight No</label>
									<input type="text" name=""  onChange={(e)=>{this.props.onPickupChnage(e,'flight_no',this.props.cartItemIndex)}} />
								</div>
								<div className="col l16 m6 s12">
									<label>Flight time</label>
									<input type="text"  name="" onChange={(e)=>{this.props.onPickupChnage(e,'flight_time',this.props.cartItemIndex)}} />
								</div>
								<div className="col l12 m12 s12">
									<label>Special Note</label>
									<textarea onChange={(e)=>{this.props.onPickupChnage(e,'remarks',this.props.cartItemIndex)}}></textarea>
								</div>
							</div>
						</div>

					</div>
				</div>







				</div>
		);
	}
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckoutItem);