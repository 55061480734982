import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { registerAccount } from '../Actions/WebsiteActions';
import { SearchActivities,SearchFeaturedActivities } from '../Actions/ActivityActions';

import Header from './Common/Header';
import Footer from './Common/Footer';
import SearchSideBar from './Common/SearchSideBar';
import GridView from './Common/GridVIew';
import ListView from './Common/ListView';
import PaginatedGridView from './Common/PaginatedGridView';
import {Helmet} from "react-helmet";

const queryString = require('query-string');


const mapStateToProps = (state) => {
    return {
        locations:state.Locations.locations,
		categories:state.Categories.categories,
    }
  }

  const mapDispatchToProps = dispatch => ({
		createAccount:(data,UserType)=>dispatch(registerAccount(data,UserType)),
		SearchActivities:(keyword,location,tourType,durationMin,durationMax,category,priceMin,priceMax,deals,featured,page,date)=>dispatch(SearchActivities(keyword,location,tourType,durationMin,durationMax,category,priceMin,priceMax,deals,featured,page,date)),
		SearchFeaturedActivities:(keyword,location,tourType,durationMin,durationMax,category,priceMin,priceMax,deals,featured,page,limit,variable)=>dispatch(SearchFeaturedActivities(keyword,location,tourType,durationMin,durationMax,category,priceMin,priceMax,deals,featured,page,limit,variable))
  });
class SearchResult extends Component {

    constructor(props){
		super(props);

    console.log(props.tourType);
		const parsed = queryString.parse(props.location.search);

        this.state = {
						view:parsed.view !== "" && parsed.view !== undefined ? parsed.view : (props.tourType !== undefined ? 'list' : 'grid'),
						searhKeywords:parsed.key !== "" && parsed.key !== undefined ? parsed.key : "",
						location:parsed.location !== "" && parsed.location !== undefined ? parsed.location : "",
						durationMin:parsed.duration_min !== "" && parsed.duration_min !== undefined ? parsed.duration_min : 1,
						durationMax:parsed.duration_max !== "" && parsed.duration_max !== undefined ? parsed.duration_max : 14,
						category:parsed.category !== "" && parsed.category !== undefined ? parsed.category : "",
						priceMin:parsed.price_min !== "" && parsed.price_min !== undefined ? parsed.price_min : 10,
						priceMax:parsed.price_max !== "" && parsed.price_max !== undefined ? parsed.price_max : 10000,
						tourType:parsed.type !== "" && parsed.type !== undefined ?  parsed.type : (props.tourType !== undefined ? props.tourType : ""),
						featured:parsed.featured !== "" && parsed.featured !== undefined ? parsed.featured : 0,
						deals:parsed.deals !== "" && parsed.deals !== undefined ? parsed.deals : false,
						page:parsed.page !== "" && parsed.page !== undefined ? parsed.page : 1,
						date:parsed.date !== "" && parsed.date !== undefined ? parsed.date : "",
					 }




		if(this.state.searhKeywords === ""){

			this.props.SearchFeaturedActivities(this.state.searhKeywords,this.state.location,1,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,"",this.state.page,15,'mostPopularRoudTours');
			this.props.SearchFeaturedActivities(this.state.searhKeywords,this.state.location,2,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,"",this.state.page,15,'mostPopularDayTours');
			this.props.SearchActivities(this.state.searhKeywords,this.state.location,this.state.tourType,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,this.state.featured,this.state.page);
		}else{

			this.props.SearchActivities(this.state.searhKeywords,this.state.location,this.state.tourType,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,this.state.featured,this.state.page);
		}

    this.props.SearchFeaturedActivities(this.state.searhKeywords,this.state.location,this.state.tourType,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,"",this.state.page,15,'featuredSearchedItems');

	}



	handleSearchParamUpdate = (params) =>{

		this.setState({
			location:params.location,
			durationMin:params.durationMin,
			durationMax:params.durationMax,
			category:params.category,
			priceMin:params.priceMin,
			priceMax:params.priceMax,
			tourType:params.tourType,
			deals:params.deals,
			page:params.page
		},()=>{
			this.props.SearchActivities(this.state.searhKeywords,this.state.location,this.state.tourType,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,this.state.featured,this.state.page);
		});
	}

	handleChangePage = (page) =>{
		this.setState({
			page:page,
		},()=>{
			this.props.SearchActivities(this.state.searhKeywords,this.state.location,this.state.tourType,this.state.durationMin,this.state.durationMax,this.state.category,this.state.priceMin,this.state.priceMax, this.state.deals,this.state.featured,this.state.page);

		});
	}

    handleUpdate = (event,field) =>{
        this.setState({
            [field]:event.target.value
        })
    }

	toggleView = () =>{
		this.setState({
			view: this.state.view === 'list' ? 'grid' : 'list',
		},()=>{ this.forceUpdate(); });
	}

	getGridView = (props) =>{
		return <GridView searchParams={props} key="gridview" categorySearch={this.state.category} />
	}

	getListView = (props) =>{
		return <ListView searchParams={props} key="gridview"  onPageChange={this.handleChangePage}  />
	}

  getPaginatedGridView = (props) =>{
		return <PaginatedGridView searchParams={props} key="gridview"  onPageChange={this.handleChangePage}  />
	}



	componentDidUpdate(){




		window.scrollTo(0,0);

	}

	getTitle = () =>{

		var title ="All Tours";

		if(this.state.tourType.toString() === "1")  title = "Round Tours";
		if(this.state.tourType.toString() === "2")  title = "Day Tours";

		if(this.state.location !== "" && this.state.location !== undefined && this.state.location !== null){
			var selLocatons = this.state.location.split(",");
			var selLocsNames = [];

			for(var x=0; x<this.props.locations.length; x++){

				for(var y=0; y<selLocatons.length;y++){

					if(this.props.locations[x].value.toString() === selLocatons[y]){
						selLocsNames.push(this.props.locations[x].label);
					}
				}
			}

			if(selLocsNames.length > 0){
				title += " in "+selLocsNames.join();
			}

		}


		if(this.state.category !== "" && this.state.category !== undefined && this.state.category !== null && this.props.categories !== undefined){
			var selCategories = this.state.category.split(",");
			var selCatNames = [];

			for(var x=0; x<this.props.categories.length; x++){

				for(var y=0; y<selCategories.length;y++){

					if(this.props.categories[x].id.toString() === selCategories[y]){
						selCatNames.push(this.props.categories[x].name);
					}
				}
			}

			if(selCatNames.length > 0){
				title += " on "+selCatNames.join();
			}

		}

		return title
	}

    render() {
        return <Fragment>
            <Header search={this.state.searhKeywords} />
				<Helmet>
						<meta charSet="utf-8" />
						<title>{this.getTitle()}</title>
						<meta name="description" content={this.getTitle()} />

					</Helmet>
            <section className="body-gry">
	<div className="container">
		<div className="row">
			<div className="col l3 m5 s12">
				<SearchSideBar location={this.props.location}  onUpdate={this.handleSearchParamUpdate} style={{marginTop:33}} />
			</div>
			<div className="col l9 m7 s12">
				<div className="we_made_you">
					<h1>{this.getTitle()}</h1>
					<div className="sort_by_row">

						<div className="viw">
							<a onClick={()=>{this.toggleView()}} className={"list "+( this.state.view === 'list' ? 'active' : null )} ></a>
							<a onClick={()=>{this.toggleView()}} className={"grid "+( this.state.view === 'grid' ? 'active' : null )} ></a>
						</div>

					</div>
					<div className="clear"></div>
				</div>
				<div>
          {console.log(this.state.view === 'list')}
          { this.state.view === 'list' && this.state.searhKeywords !== "" ? this.getListView(this.state) : null }
          { this.state.view === 'grid' && this.state.searhKeywords !== "" ? this.getPaginatedGridView(this.state) : null }
          { this.state.view === 'grid' && this.state.searhKeywords === "" ? this.getGridView(this.state) : null }
          { this.state.view === 'list' && this.state.searhKeywords === "" ? this.getListView(this.state) : null }
        </div>
			</div>
		</div>
	</div>
</section>
            <Footer />

        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SearchResult);
