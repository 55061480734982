import React, { Props, Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { validateUser, storeAuthToken, fetchUser, storeUser,HideLogin,LoginWithSocialMedia } from '../../Actions/AuthActions';
import { SnackBar } from '../../Actions/CommonActions';

import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

import {Link} from 'react-router-dom'





const mapStateToProps = (state) => {
    return {
        showLoginModal:state.Auth.showLoginModal,		
        loggedIn:state.Auth.loggedIn,
        loading : state.Common.loading,        
    }
  }
  
  const mapDispatchToProps = dispatch => ({		
        validateUser : (username,password) => dispatch(validateUser(username,password)),
        SnackBar : (show,message,varient) => dispatch(SnackBar(show,message,varient)),
        HideLogin:()=>dispatch(HideLogin()),
        LoginWithSocialMedia:(user)=>dispatch(LoginWithSocialMedia(user))
  });
class LoginModal extends Component {

    constructor(props){
		super(props);

		this.state = {
            username: "",
            password:"",
            loggedIn:false,
            loading:false,
        };
	}

    uiConfig = {
		signInFlow: "popup",
		signInOptions: [
		  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		  firebase.auth.FacebookAuthProvider.PROVIDER_ID,
		  firebase.auth.TwitterAuthProvider.PROVIDER_ID,		  
		  
		],
		callbacks: {
		  signInSuccess: () => false
		}
      }
      
      componentDidMount = () => {

		

		firebase.auth().onAuthStateChanged(user => {
		  if(user !== null && this.props.loggedIn === false){
			this.props.LoginWithSocialMedia(user);		  
		  }			
		  
		})
	  }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
	  };
	  

	  login(){
        console.log('erer');
        if(this.state.username.length == 0){
            this.props.SnackBar(true,"We need your Email",'error');
            return false;
        }

        if(this.state.password.length == 0){
            this.props.SnackBar(true,"Password can not be empty",'error');
        }
        
        this.props.validateUser(this.state.username,this.state.password);
        
    }

    render() {
        return (
            <Fragment>
               <Dialog open={this.props.showLoginModal} scroll="body"  aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Login</DialogTitle>
                    <DialogContent>                    
                    <div className="cont-log" style={{width:500}}>
                    <div className="log-head" style={{width:'85%', marginTop:0}}>
					
                    <div className="inp">
                    <div  style={{  display:"flex"}}    ><label>Email Address</label> {this.state.username===""?<label style= {{color:"red"   }}>*required</label>:null }    </div>	
                        <input type="text" 
                                value={this.state.username}
                               onChange={this.handleChange('username')} />
                    </div>
                    <div className="inp">
                    <div  style={{  display:"flex"}}    ><label>Password</label> {this.state.password===""?<label style= {{color:"red"   }}>*required</label>:null }    </div>	
                        
                        <input type="password" 
                               value={this.state.password}
                               onChange={this.handleChange('password')}	 />
                    </div>
                    <div className="forgot">
                        <a href="">Forgot password?</a>
                    </div>
                    <div className="log-btn">
                        <i className="waves-effect waves-light btn">
                            <input  type="submit" value="Login" onClick={()=>{ this.login();}} />
                        </i>
                        
                    </div>
                    <div className="sinwith">
							<p> Or Sign in with </p>
							<div>
                            <StyledFirebaseAuth
									uiConfig={this.uiConfig}
									firebaseAuth={firebase.auth()}
								/>
								<div className="clear"></div>
							</div>
						</div>
                    <div className="hv-acunt">
                        <p>Don't have an account yet? <Link to={'/account/signup'}>Sign Up</Link></p>
                    </div>					
            </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.HideLogin} color="primary">
                            Cancel
                        </Button>                     
                    </DialogActions>
                </Dialog>     
            </Fragment>
        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(LoginModal);