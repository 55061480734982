export default function reducer(state = {
    data: null,
    reviewSuccess: false
}, action) {
    switch (action.type) {
        case "STORE_REVIEW_MAIL_MOOD": {
            return { ...state, data: action.data }
        }
        case "REVIEW_ADDED_SUCCESSFULLY": {
            return { ...state, reviewSuccess: true }
        }
        default:
            break;
    }
    return state
}