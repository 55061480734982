import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { GetFavorites, FavoriteRemoveItem } from '../Actions/FavoritetActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';
import StarRatingComponent from 'react-star-rating-component';
import { Helmet } from "react-helmet";


const mapStateToProps = (state) => {
	return {
		favorites: state.Favorites.list,
	}
}

const mapDispatchToProps = dispatch => ({
	GetFavorites: () => dispatch(GetFavorites()),
	FavoriteRemoveItem: (ItemID) => dispatch(FavoriteRemoveItem(ItemID))
});
class MyFavorites extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
		}

		this.props.GetFavorites();
	}


	getTripType(type) {

		switch (type) {
			case 1: { return "Round tour" }
			case 2: { return "Day Tour" }
			case 3: { return "Activity" }
			case 4: { return "Transport" }
		}
	}

	onRemoveItem = (itemId) => {

		this.props.FavoriteRemoveItem(itemId);
	}


	render() {
		return <Fragment>
			<Header />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{"My Favorites"}</title>
			</Helmet>
			<section className="body-gry setting-pg">
				<div className="container">
					<div className="row">
						<div className="col l4 m5 s12">
							<AccountNavigation />
						</div>

						<div className="col l8 m7 s12">
							<div className="set-cont">
								<div className="hed-bed">
									<div className="bred-cmp">
										<a href="">Dashboard</a> / <span>Favourites</span>
									</div>
									<h1>Favourites</h1>
								</div>
								<div className="set-act-div favourites">
									<div className="row">
										{this.props.favorites.map((item) => {
											return <div className="col l4 m12 s12">
												<div className="grid-wv tour-list">
													<div className="img">
														{item.activity.image !== null ? (
															<img src={item.activity.cover_image} />
														) : null}

													</div>
													<div className="cont">
														<h6>{item.activity.title}</h6>
														<p>{item.activity.overview.substr(0, 90)}...</p>
														<div>
															<p><span>TOUR TYPE	</span>{item.activity.tour_type.type}</p>
														</div>
													</div>
													<div className="tou-fot">
														<div>
															<div className="reviews2">
																<StarRatingComponent
																	name="rate2"
																	editing={false}
																	starCount={5}
																	value={item.activity.rating_count}
																	renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}

																/>
																<span className="reviews">Reviews (0)</span>
															</div>
														</div>
														<div className="pric-side">
															<span className="price">USD {item.activity.price.selling_price}</span>
															<span className="st-price">Starting Price</span>
														</div>
														<div className="clear"></div>
													</div>
													<div className="tur-btn">
														<a className="waves-effect waves-light btn" href={"/activity/details/" + item.activity.id}>View Tour Now</a>
													</div>
													<div className="ri" onClick={() => { this.onRemoveItem(item.id); }}><span>X</span>Remove Item</div>
												</div>
											</div>;
										})}



									</div>
								</div>

							</div>
						</div>
					</div>
				</div>

			</section>
			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyFavorites);
