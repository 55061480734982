import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import {ShowLogin} from './AuthActions';
import store from '../store';
import axios from 'axios';


export function FavoriteAddItem(ItemID){

    return function(dispatch) {
        if(store.getState().Auth.authToken === undefined || store.getState().Auth.authToken === null){
            dispatch(ShowLogin());
            return null;
        } 


        dispatch(SnackBar(true,'Adding item to the favorites list','info'));
        fetch(API_URL+'/api/account/favourites/add/'+ItemID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8', 
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token             
            },            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                  
                  dispatch(GetFavorites());                   
                  dispatch(SnackBar(true,'Item added to favorites','success'));                                    
              });
        }).catch((error)=>{
                        
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not process action','error'));
        });

    }   
}



export function GetFavorites(){

    return function(dispatch) {
        if(store.getState().Auth.authToken === undefined || store.getState().Auth.authToken === null){            
            return null;
        } 

        fetch(API_URL+'/api/account/favourites',{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8', 
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token             
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                  dispatch(storeCartItems(json));             
              });
        }).catch((error)=>{
                        
          dispatch(endLoading());            
          dispatch(SnackBar(true,'No data found','error'));
        });

    }   
}

export function storeCartItems(results) {
    return {
        type: 'STORE_FAVORITE_ITEMS',
        list:results.data,      
                 
    }
}


export function FavoriteRemoveItem(ItemID){

    return function(dispatch) {
        if(store.getState().Auth.authToken === undefined || store.getState().Auth.authToken === null){
            dispatch(ShowLogin());
            return null;
        } 

        console.log(ItemID);
        dispatch(SnackBar(true,'Removing item from favorites','info'));
        fetch(API_URL+'/api/account/favourites/remove/'+ItemID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8', 
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token             
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                  
                  dispatch(GetFavorites());                  
                  dispatch(SnackBar(true,'Item removed','success'));                                    
              });
        }).catch((error)=>{
                        
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not process action','error'));
        });

    }   
}