export default function reducer(state={
    locations:[]
},action){

    switch(action.type){
        case "STORE_LOCATIONS":{
            return{...state,locations:action.locations}
        }         
    }

    return state;
}