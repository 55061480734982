import React, { Props, Component, Fragment } from 'react';


import SearchResultGridItem from './SearchResultGridItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { compose } from 'redux';
import { connect } from 'react-redux';

import OwlCarousel from 'react-owl-carousel';
import loadingAnim from '../../Images/loading.gif';

const $ = window.jQuery;


const mapStateToProps = (state) => {
    return {
		featuredSearchedItems:state.Activities.featuredSearchedItems,
		mostPopularRoudTours:state.Activities.mostPopularRoudTours,
		mostPopularDayTours:state.Activities.mostPopularDayTours,
		featuredSearchedItemsLoading:state.Common.featuredSearchedItemsLoading,
		mostPopularDayToursLoading:state.Common.mostPopularDayToursLoading,
		mostPopularRoudToursLoading:state.Common.mostPopularRoudToursLoading
    }
  }

  const mapDispatchToProps = dispatch => ({

  });
class GridView extends Component {
	owlFeaturedTours = React.createRef();
	owlPopularRoundTours = React.createRef();
	owlPopularDayTours	 = React.createRef();

    constructor(props){
        super(props)

        this.state = {
			view:this.props.view,
			roudToursLoaded:false,
			dayToursLoaded:false,
			faturedToursLoaded:false,

        }
    }

    componentDidMount =()=>{


  		if(this.props.mostPopularRoudTours !== null && this.state.roudToursLoaded === false){

  			$(this.owlPopularRoundTours.current).owlCarousel({
  				loop: false,
  				responsiveClass: true,
  				responsive: {
  					0: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 0
  					},
  					600: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 10,
  					},
  					1000: {
  						items: 3,
  						loop: false,
  						nav: false,
  						dote: false,
  						margin: 30,
  					}
  				}
  			});

  			this.setState({
  				roudToursLoaded:true
  			})
  		}

  		if(this.props.mostPopularDayTours !== null && this.state.dayToursLoaded === false){

  			$(this.owlPopularDayTours.current).owlCarousel({
  				loop: false,
  				responsiveClass: true,
  				responsive: {
  					0: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 0
  					},
  					600: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 10,
  					},
  					1000: {
  						items: 3,
  						loop: false,
  						nav: false,
  						dote: false,
  						margin: 30,
  					}
  				}
  			});

  			this.setState({
  				dayToursLoaded:true
  			})
  		}

  		if(this.props.featuredSearchedItems !== null && this.state.faturedToursLoaded === false){

  			$(this.owlFeaturedTours.current).owlCarousel({
  				loop: false,
  				responsiveClass: true,
  				responsive: {
  					0: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 0
  					},
  					600: {
  						items: 1,
  						nav: false,
  						dote: true,
  						loop: true,
  						margin: 10,
  					},
  					1000: {
  						items: 3,
  						loop: false,
  						nav: false,
  						dote: false,
  						margin: 30,
  					}
  				}
  			});

  			this.setState({
  				faturedToursLoaded:true
  			})
  		}

  		// window.$('.owl-tour-scr').owlCarousel('destroy');
  		// window.$('.owl-tour-scr').owlCarousel({
  		// 	loop: false,
  		// 	responsiveClass: true,
  		// 	responsive: {
  		// 		0: {
  		// 			items: 1,
  		// 			nav: false,
  		// 			dote: true,
  		// 			loop: true,
  		// 			margin: 0
  		// 		},
  		// 		600: {
  		// 			items: 1,
  		// 			nav: false,
  		// 			dote: true,
  		// 			loop: true,
  		// 			margin: 10,
  		// 		},
  		// 		1000: {
  		// 			items: 3,
  		// 			loop: false,
  		// 			nav: false,
  		// 			dote: false,
  		// 			margin: 30,
  		// 		}
  		// 	}
  		// });
  	}


	componentDidUpdate = () =>{


		if(this.props.mostPopularRoudTours !== null && this.state.roudToursLoaded === false){

			$(this.owlPopularRoundTours.current).owlCarousel({
				loop: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 0
					},
					600: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 10,
					},
					1000: {
						items: 3,
						loop: false,
						nav: false,
						dote: false,
						margin: 30,
					}
				}
			});

			this.setState({
				roudToursLoaded:true
			})
		}

		if(this.props.mostPopularDayTours !== null && this.state.dayToursLoaded === false){

			$(this.owlPopularDayTours.current).owlCarousel({
				loop: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 0
					},
					600: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 10,
					},
					1000: {
						items: 3,
						loop: false,
						nav: false,
						dote: false,
						margin: 30,
					}
				}
			});

			this.setState({
				dayToursLoaded:true
			})
		}

		if(this.props.featuredSearchedItems !== null && this.state.faturedToursLoaded === false){

			$(this.owlFeaturedTours.current).owlCarousel({
				loop: false,
				responsiveClass: true,
				responsive: {
					0: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 0
					},
					600: {
						items: 1,
						nav: false,
						dote: true,
						loop: true,
						margin: 10,
					},
					1000: {
						items: 3,
						loop: false,
						nav: false,
						dote: false,
						margin: 30,
					}
				}
			});

			this.setState({
				faturedToursLoaded:true
			})
		}

		// window.$('.owl-tour-scr').owlCarousel('destroy');
		// window.$('.owl-tour-scr').owlCarousel({
		// 	loop: false,
		// 	responsiveClass: true,
		// 	responsive: {
		// 		0: {
		// 			items: 1,
		// 			nav: false,
		// 			dote: true,
		// 			loop: true,
		// 			margin: 0
		// 		},
		// 		600: {
		// 			items: 1,
		// 			nav: false,
		// 			dote: true,
		// 			loop: true,
		// 			margin: 10,
		// 		},
		// 		1000: {
		// 			items: 3,
		// 			loop: false,
		// 			nav: false,
		// 			dote: false,
		// 			margin: 30,
		// 		}
		// 	}
		// });
	}


    render() {

		var featuredItems = 0;
		var dayTours = 0;
		var roundTours = 0;

        return (
            <div>
              <div class="row">
  							<div class="col l12 m12 s12">
  								<div class="tou-hed">
  									<h3>Made for you</h3>
  								</div>
  							</div>
  						</div>
				{ this.props.featuredSearchedItemsLoading === true ? (<div style={{width:'100%',minHeight:200,textAlign:'center'}} ><img src={loadingAnim} className="loading-animation"/></div>):null}

				{this.props.featuredSearchedItems !== null && this.props.featuredSearchedItems.length > 0 ?  (
					<Fragment>

						<div class="row">
							<div class="col l12 m12 s12">


									<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlFeaturedTours}>
									{this.props.featuredSearchedItems !== null ? this.props.featuredSearchedItems.map((item,index)=>{
										if(featuredItems > 15) return null;
										//if(item.featured !== 1) return null;
										featuredItems++;
										return <SearchResultGridItem searchParams={this.props.searchParams} data={item} key={"gv-"+index} />;
									}):null}

									</div>



							</div>
						</div>
						<div class="row">
					<div class="col l12 m12 s12">
						<div class="vw-al">
							<a href={'./search?view=list&featured=1'+(this.props.categorySearch !== "" ? "&category="+this.props.categorySearch:"")}>View more</a>
						</div>
					</div>
				</div>
					</Fragment>
				):null}
        <div class="row">
          <div class="col l12 m12 s12">
            <div class="tou-hed">
              <h3>Most Popular Day Tours</h3>
            </div>
          </div>
        </div>
					{ this.props.mostPopularDayToursLoading === true ? (<div style={{width:'100%',minHeight:200,textAlign:'center'}} ><img src={loadingAnim} className="loading-animation"/></div>):null}
					{this.props.mostPopularDayTours !== null ? (
					<Fragment>

						<div class="row">
					<div class="col l12 m12 s12">

						<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlPopularDayTours} >

						{

							this.props.mostPopularDayTours !== null ? this.props.mostPopularDayTours.map((item,index)=>{
							if(dayTours > 15) return null;
							if(item.tour_type !== 2) return null;
							dayTours++;
							return <SearchResultGridItem searchParams={this.props.searchParams} data={item} key={"gv-"+index} />;
						}):null}
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col l12 m12 s12">
						<div class="vw-al">
							<a href="./search?view=list&type=2">View more</a>
						</div>
					</div>
				</div>
					</Fragment>
				):null}
        <div class="row">
          <div class="col l12 m12 s12">
            <div class="tou-hed">
              <h3>Most Popular Round Tours</h3>
            </div>
          </div>
        </div>
				{ this.props.mostPopularRoudToursLoading === true ? (<div style={{width:'100%',minHeight:200,textAlign:'center'}} ><img src={loadingAnim} className="loading-animation"/></div>):null}
				{this.props.mostPopularRoudTours !== null ? (
					<Fragment>

						<div class="row">
					<div class="col l12 m12 s12">


						<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlPopularRoundTours} >
						{this.props.mostPopularRoudTours !== null ? this.props.mostPopularRoudTours.map((item,index)=>{
							if(roundTours > 15) return null;
							if(item.tour_type !== 1) return null;
							roundTours++;
							return <SearchResultGridItem searchParams={this.props.searchParams} data={item} key={"gv-"+index} />;
						}):null}
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col l12 m12 s12">
						<div class="vw-al">
							<a href="./search?view=list&type=1">View more</a>
						</div>
					</div>
				</div>
					</Fragment>
				):null}







            </div>
        );
    }
}

GridView.defaultProps = {
    home: false,
  }


export default compose(connect(mapStateToProps, mapDispatchToProps))(GridView);
