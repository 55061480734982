import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import StarRatingComponent from 'react-star-rating-component'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 470,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
    outline: 'none',
  },
}));

export default function SimpleModal(props) {
  const classes = useStyles()
  function rateCount(newValue) {
    if (props.submit) {
      props.rateCount(newValue)
    }
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
    >
      <div className={classes.paper + ' rw-pop'}>
        <div className="modal-content">
          <a onClick={() => { props.onClose() }} className="modal-close"><img src="/img/cs-pop.png" /></a>

          <div className="rw-top">
            <div className="rw-img">
              {props.data !== null && props.data.activity.activity_images !== null ? (
                <img src={props.data.activity.activity_images[0].name} />
              ) : null}
            </div>
            <div className="rw-cont">
              <h4>{props.data !== null ? props.data.activity.title : ""}</h4>
              <p>{props.data !== null ? props.data.activity.overview.substr(0, 90) : ""}</p>
            </div>
          </div>
          <div className="rw-bot">
            <h4>Booking Reference</h4>
            {props.data !== null ? props.data.booking_ref : null}
          </div>
          <div className="rw-bot">
            <h4>Rate your activity </h4>
            <div className="ret">
              <StarRatingComponent
                name="rate2"
                starCount={5}
                value={props.rate}
                renderStarIcon={() => <i style={{ fontSize: 48, fontStyle: 'normal' }} >★</i>}
                onStarClick={(newValue) => { rateCount(newValue) }}
              />
            </div>
            <div className="frm">
              <div>
                <textarea disabled={!props.submit} value={props.feedback} name="feedback" rows={3} id="rate" maxlength="200" onChange={props.handlechange} placeholder="Write your review...."></textarea>
                <span id="cont"></span>
                {props.submit ?
                  <i className="waves-effect waves-light btn">
                    <input type="button" onClick={() => { props.AddReview(); }} style={{ cursor: 'pointer' }} value="Submit Review" name="" />
                  </i> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
