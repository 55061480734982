import React, { Props, Component, Fragment } from 'react';



class RoundTourItinery extends Component {

	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="day-lis">
				
				{this.props.itinery !== null ? this.props.itinery.map((item,i)=>{
					return <div key={"it-"+i} className="day">
					<div className="lef-day">
						<span className="num">{i+1}</span>
						<span className="day-nm">DAY</span>
					</div>
					{/* <h6>8.30 AM – 9.30 AM: Transportation</h6> */}
					<p>{item.summery}</p>
				</div>;
				}):null}
				
				

			</div>
		);
	}
}

RoundTourItinery.defaultProps = {
	home: false,
}


export default (RoundTourItinery);