import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';

import { registerAccount } from '../Actions/WebsiteActions';

import Header from './Common/Header';
import Footer from './Common/Footer';




const mapStateToProps = (state) => {
    return {
        supplierSignUpComplete:state.Website.supplierSignUpComplete
    }
  }

  const mapDispatchToProps = dispatch => ({
        createAccount:(data,UserType)=>dispatch(registerAccount(data,UserType))
  });
class SupplierSignUpComplete extends Component {

    constructor(props){
        super(props);


        this.state = {
                        first_name:null,
                        last_name:null,
                        email:null,
                        password:null,
                        confim_password:null

                     }

                         }

    handleUpdate = (event,field) =>{
        this.setState({
            [field]:event.target.value
        })
    }

    doRegistration = () =>{
        this.props.createAccount(this.state,2);
    }

    SupplierAccountLogin = () =>{
        window.location.href="https://supplier.aktivitar.com/";
    }


    componentDidMount() {
        window.scrollTo(0,0)
      }



    render() {
        return <Fragment>
            <Header />
            <section className="gry-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                            <h1>Thank you!</h1>
                                <p>Your account was sucessfully created, please <a style={{cursor:'pointer'}} onClick={()=>{this.SupplierAccountLogin()}}>  login</a> to your account and complete your profile. While we will review your account. you can start creating your activities right away.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cont-log">
                <div className="row">
                    <div className="container">
                        <div className="col l12 m12 s12">
                            <div className="log-head">
                                    <div className="log-btn">
                                        <i className="waves-effect waves-light btn waves-input-wrapper"><input type="submit" onClick={()=>{this.SupplierAccountLogin()}}  style={{cursor:'pointer'}}  value="Supplier Account Login" /></i>


                                    </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SupplierSignUpComplete);
