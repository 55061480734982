import React, { Props, Component, Fragment } from 'react';

import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from "firebase"

import { Logout } from '../../Actions/AuthActions';

const mapStateToProps = (state) => {
	return {
        loggedIn:state.Auth.loggedIn,
        user:state.Auth.user
	}
}

const mapDispatchToProps = dispatch => ({
	Logout:()=>dispatch(Logout())
});
class AccountNavigation extends Component {

    constructor(props) {
        super(props)
    }

    componentDidUpdate = () =>{
        if(this.props.loggedIn === false){
            window.location.href='/';
        }
    }

    logout = () => {
        this.props.Logout();
        try{
            firebase.auth().signOut();
        }catch (e){

        }
    }

    render() {
        return (
            <div className="settings-menu">
                <div className="pro-nam">
                    <div className="pro-pic">
                        <img src="" />
                    </div>
                    <div className="pro-nm">
                        <h4>{this.props.user.first_name} {this.props.user.last_name}</h4>
                       
                    </div>
                </div>
                <div className="set-rsbtn">
                    <span></span>
                    <span></span>
                </div>
                <ul className="set-mn">
                    <li className="my-dash"><Link to="/account">Dashboard</Link></li>
                    <li className="bookings ac"><Link to="/account/bookings">My Bookings</Link></li>
                    <li className="reviews"><Link to="/account/reviews">Reviews & Ratings</Link></li>
                    <li className="deals"><Link to="/account/deals">Deals & Discounts</Link></li>
                    <li className="my-favo"><Link to="/account/favorites">My Favorites</Link></li>
                    <li className="newsletter"><Link Link to="/account/settings" >Settings</Link></li>
                    <li className="log_out"><a style={{cursor:'pointer'}} onClick={()=>this.logout()}>Log Out</a></li>
                </ul>
            </div>

        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(AccountNavigation);