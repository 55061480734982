import React, { Props, Component, Fragment } from 'react';


import { compose } from 'redux';
import { connect } from 'react-redux';


import Header from './Common/Header';
import Footer from './Common/Footer';

import { MyBookings } from '../Actions/WebsiteActions';
import { Link } from 'react-router-dom';
import AccountNavigation from './Common/AccountNavigation';
import Notification from '@material-ui/icons/NotificationsActive';
import {Helmet} from "react-helmet";

const mapStateToProps = (state) => {
	return {
		mybookings:state.Website.mybookings,
	}
}

const mapDispatchToProps = dispatch => ({
	MyBookings:()=>dispatch(MyBookings())
});
class MyAccountBookings extends Component {

	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
		}


		this.props.MyBookings();
	}


	handleChange = (event, field) => {

		this.setState({
			[field]: event.target.value
		}, () => {

			this.props.getSearchSuggestions(this.state.searchField);

		});
	}


	render() {

		var upcomingTours = 0;
		var completedTours = 0;
		return <Fragment>
			<Header  />
			<Helmet>
				<meta charSet="utf-8" />
				<title>{"My Bookings"}</title>
			</Helmet>
			<section className="body-gry setting-pg">
				<div className="container">
					<div className="row">
						<div className="col l4 m5 s12">
							<AccountNavigation/>
						</div>

							<div className="col l8 m7 s12">
								<div className="set-cont">
									<div className="hed-bed">
										<div className="bred-cmp">
											<a href="">Home</a> / <span>About Us</span>
						</div>
												<h1>About us</h1>
					</div>
											<div className="upcomeing">
												<table className="upcomeing-tbl upc">
													<thead>
														<tr>
															<th colspan="2" width="30.5%">
																<h5>Upcoming Tours</h5>
															</th>
															<th className="tc" width="12.5%">Booking Date</th>
															<th className="tc" width="10%">Cost</th>
															<th className="tc" width="10%">No of Pax</th>
															<th className="tc" width="14.5%" >Supplier</th>
															<th width="12.5%" ></th>
														</tr>
													</thead>
													<tbody>

														{this.props.mybookings.data.map((item)=>{
															console.log(item)

															if(item.status === 4 || item.status === 5 ) {
																return null;
															}

															upcomingTours++;

															return <tr className="back-wit">
															<td>
																<img src={item.activity.cover_image} width={91} height="auto" style={{width:91,height:'auto'}} />
								</td>
																<td className="pd-l" style={{verticalAlign:'middle'}}>
																	<h6 className="ae">{item.activity.title}
																	{item.alert_client === 1 ? (<Notification color="error" fontSize="small"/>):null}
																	</h6>
																	<p className="av">{item.activity.overview.substr(0,30)}</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl" >{item.start_date}</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl" >{item.booking_amount} USD</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl">{item.num_adults}A/{item.num_children}C/{item.num_infants}I</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="sp">{item.activity.user.supplier_profile.work_name}</p>
																</td>
																<td className="tc"  style={{verticalAlign:'middle'}}>
																		<Link to={'/account/booking/detail/'+item.id} className="waves-effect waves-light btn">View</Link>
																	</td>
															</tr>;
														})}


																{ upcomingTours === 0 ? (
																	<tr className="back-wit">
																		<td colSpan={7} style={{padding:8,textAlign:'center'}} ><p>No upcoming tours found </p></td>
																	</tr>
																):null}

																</tbody>
															</table>
					</div>
													<div className="upcomeing">
														<table className="upcomeing-tbl completed-btl">
															<thead>
																<tr>
																	<th width="30.5%">
																		<h5>Completed Tours</h5>
																	</th>
																	<th className="tc" width="12.5%">Booking Date</th>
																	<th className="tc" width="10%">Cost</th>
																	<th className="tc" width="10%">No of Pax</th>
																	<th className="tc" width="16.5%" >Supplier</th>
																	<th width="10%" ></th>
																</tr>
															</thead>
															<tbody>
															{this.props.mybookings.data.map((item)=>{
																console.log(item)
															if(item.status !== 4 && item.status !== 5 ) {
																return null;
															}
																completedTours++;
																return <tr className="back-wit">
																	<td className="pd-l" style={{verticalAlign:'middle'}}>
																	<h6 className="ae">{item.activity.title}
																	{item.alert_client === 1 ? (<Notification color="error" fontSize="small"/>):null}
																	</h6>
																	<p className="av">{item.activity.overview.substr(0,30)}</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl" >{item.start_date}</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl" >{item.booking_amount} USD</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="bl">{item.num_adults}A/{item.num_children}C/{item.num_infants}I</p>
																</td>
																<td className="tc" style={{verticalAlign:'middle'}}>
																	<p className="sp">{item.activity.user.supplier_profile.work_name}</p>
																</td>
																	<td className="tc">
																		<Link to={'/account/booking/detail/'+item.id}>View</Link>
																	</td>
																</tr>;
														})}
																{ completedTours === 0 ? (
																	<tr className="back-wit">
																		<td colSpan={7} style={{padding:8,textAlign:'center'}} ><p>No upcoming tours found </p></td>
																	</tr>
																):null}
															</tbody>
														</table>
													</div>
				</div>
											</div>
										</div>
									</div>
</section>
								<Footer />

                </Fragment>
							}
						}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAccountBookings);
