export default function reducer(state={
    profile: {
                    id: 0,
                    name: null,
                    email: null,
                    user_type: 2,
                    status: 0,
                    work_name: null,
                    work_address: null,
                    work_reg_number: null,
                    work_fix_phone: null,
                    work_mobile: null,
                    work_avatar: null,
                    contact_name: null,
                    contact_NIC: null,
                    contact_home_phone: null,
                    contact_mobile_phone: null,
                    contact_address: null,
                    contact_avatar: null,
                    categories: new Map()
                },    
},action){
switch(action.type){
    case "STORE_ACCOUNT":{
        return{...state,profile:action.account}
    }    
}
return state
}