import React, { Props, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Select from './ControlSelect';
import {fetchLocations} from '../../Actions/LocationAction';
import {fetchCategories} from '../../Actions/CategoryAction';
import {fetchEmail} from '../../Actions/CommonActions';
import {SnackBar} from '../../Actions/CommonActions';
import Chip from '@material-ui/core/Chip';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { copyFileSync } from 'fs';

const queryString = require('query-string');


const useStyles = makeStyles(theme => ({
	root: {
	  width: 300 + 24 * 2,
	  padding: 24,
	},
	margin: {
	  height: theme.spacing(3),
	},
  }));


  function ValueLabelComponent(props) {
	const { children, open, value } = props;

	const popperRef = React.useRef(null);
	React.useEffect(() => {
	  if (popperRef.current) {
		popperRef.current.update();
	  }
	});

	return (
	  <Tooltip
		PopperProps={{
		  popperRef,
		}}
		open={open}
		enterTouchDelay={0}
		placement="top"
		title={value}
	  >
		{children}
	  </Tooltip>
	);
  }

  ValueLabelComponent.propTypes = {
	children: PropTypes.element.isRequired,
	open: PropTypes.bool.isRequired,
	value: PropTypes.number.isRequired,
  }


  const PrettoSlider = withStyles({
	root: {
	  color: '#52af77',
	  height: 8,
	},
	thumb: {
	  height: 24,
	  width: 24,
	  backgroundColor: '#fff',
	  border: '2px solid currentColor',
	  marginTop: -8,
	  marginLeft: -12,
	  '&:focus,&:hover,&$active': {
		boxShadow: 'inherit',
	  },
	},
	active: {},
	valueLabel: {
	  left: 'calc(-50% + 4px)',
	},
	track: {
	  height: 4,
	  borderRadius: 2,
	},
	rail: {
	  height: 4,
	  borderRadius: 2,
	},
  })(Slider);


  const mapStateToProps = (state) => {
    return {
		locations:state.Locations.locations,
		categories:state.Categories.categories,
		snackbar:state.Common.snackbar
    }
  }

  const mapDispatchToProps = dispatch => ({
	fetchLocations:()=>dispatch(fetchLocations()),
	fetchCategories:()=>dispatch(fetchCategories()),
	fetchEmail:(email)=>dispatch(fetchEmail(email)),
	SnackBar:(show,message,varient)=>dispatch(SnackBar(show,message,varient))
  });
class SearchSideBar extends Component {

    constructor(props){
		super(props)

		var parsed = queryString.parse(this.props.location.search);


		this.state = {
			locationOpen:false,
			categoryOpen:false,
			otherOpen:false,
			view:parsed.view !== "" && parsed.view !== undefined ? parsed.view : 'grid',
			textValue: parsed.key !== "" && parsed.key !== undefined ? parsed.key : "",
			percent: null,
			location: "",
			durationMin:parsed.duration_min !== "" && parsed.duration_min !== undefined ? parsed.duration_min : 1,
			durationMax:parsed.duration_max !== "" && parsed.duration_max !== undefined ? parsed.duration_max : 14,
			category: "",
			priceMin:parsed.price_min !== "" && parsed.price_min !== undefined ? parsed.price_min : 10,
			priceMax:parsed.price_max !== "" && parsed.price_max !== undefined ? parsed.price_max : 1000,
			tourType:parsed.type !== "" && parsed.type !== undefined ? parsed.type : "",
			selecetedCategories:parsed.category !== "" && parsed.category !== undefined ? parsed.category.split(",") : "",
			selecetedLocations:parsed.location !== "" && parsed.location !== undefined ? parsed.location.split(",") : "",
			deals:parsed.deals !== "" && parsed.deals !== undefined ? parsed.deals : false,
			page:parsed.page !== "" && parsed.page !== undefined ? parsed.page : 1,
			email:null
		}


		this.props.fetchLocations();
		this.props.fetchCategories();
		//this.props.fetchEmail(this.state.email);

	}

	sliderRef;


	updateSearch = () =>{

		var query = "";

		if(this.state.textValue !== ""){
			query ="key="+this.state.textValue;
		}

		if(this.state.view !== null){
			query = query !== "" ? query+"&":"";
			query = query+"view="+this.state.view;
		}

		if(this.state.tourType !== null){
			query = query !== "" ? query+"&":"";
			query = query+"type="+this.state.tourType;
		}

		if(this.state.durationMin !== null){
			query = query !== "" ? query+"&":"";
			query = query+"duration_min="+ this.state.durationMin;
		}

		if(this.state.durationMax !== null){
			query = query !== "" ? query+"&":"";
			query = query+"duration_max="+ this.state.durationMax;
		}

		if(this.state.priceMin !== null){
			query = query !== "" ? query+"&":"";
			query = query+"price_min="+ this.state.priceMin;
		}

		if(this.state.priceMax !== null){
			query = query !== "" ? query+"&":"";
			query = query+"price_max="+ this.state.priceMax;
		}

		if(this.state.selecetedCategories !== null && this.state.selecetedCategories.length > 0){

			query = query !== "" ? query+"&":"";
			query = query+"category="+ this.state.selecetedCategories.join();
		}

		if(this.state.selecetedLocations !== null && this.state.selecetedLocations.length > 0){
			query = query !== "" ? query+"&":"";
			query = query+"location="+ this.state.selecetedLocations.join();
		}

		if(this.state.deals !== false){
			query = query !== "" ? query+"&":"";
			query = query+"deals=true";
		}

		if(this.state.page !== null){
			query = query !== "" ? query+"&":"";
			query = query+"page="+this.state.page;
		}


		window.location.href="/search?"+query;
	}

	setSliderRef = ( ref) =>{
		this.sliderRef = ref;
	}


	componentDidUpdate(){

		try{

			// window.noUiSlider.create(this.sliderRef, {
			// 	start: [30, 75],
			// 	connect: true,
			// 	tooltips: [ window.wNumb({ decimals: 0 }), window.wNumb({ decimals: 0 }) ],
			// 	range: {
			// 		'min': 0,
			// 		'max': 100
			// 	}
			// });

		}catch(e){

		}
	}

	handleChangeDuration = (val) =>{
		this.setState({
			durationMin:val[0],
			durationMax:val[1],
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	handleChangePrice = (val) =>{
		this.setState({
			priceMin:val[0],
			priceMax:val[1],
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}



	handleTourType = (type) =>{



		this.setState({
			tourType:type,
			view:'list',
			textValue: type === "" ? "": this.state.textValue
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	handleCategory = (event) =>{

        this.setState({
			...this.state,
			selecetedCategories:[
				...this.state.selecetedCategories,
				event.target.value
			],
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	removeCategory = (itemId) =>{


		var cats = this.state.selecetedCategories.filter(function(value, index, arr){

			return value.toString() !== itemId.toString();

		});


        this.setState({
			...this.state,
			selecetedCategories:cats,
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}


	handleLocations = (event) =>{

        this.setState({
			...this.state,
			selecetedLocations:[
				...this.state.selecetedLocations,
				event.target.value
			],
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	removeLocation = (locId) =>{

		var locs = this.state.selecetedLocations.filter(function(value, index, arr){

			return value.toString() !== locId.toString();

		});

        this.setState({
			...this.state,
			selecetedLocations:locs,
			view:'list'
		},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	handleChange = (event,field) =>{

        this.setState({[field]:event.target.value,view:'list'},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
	}

	    handleChange = ( event, name ) => {
      this.setState({
        [name]: event.target.value,
      });

    };





    sentEmail = ()=>{

    if( this.state.email===null || this.state.email === "" )
      {
        this.props.SnackBar(true,'Email field is mandatory','error');
        return false;

      }

	  var sendEmail = this.state.email;

	  
	const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	console.log(reg.test(sendEmail))
	if(reg.test(sendEmail) !== true){
		this.props.SnackBar(true,'Email not valid','error');
			return false;
	}

	  this.setState({
		  email:""
	  },()=> this.props.fetchEmail(sendEmail));



  }



	handleDealChange = (event) =>{

        this.setState({deals:event.target.checked,view:'list'},()=>{
			this.updateSearch();
			//this.props.onUpdate(this.state);
		});
    }

    render() {
        return (
            <div className="search_tours">

					<div className="tour-menu" style={this.props.style} >
						<a className="all_tours" onClick={ (e)=>{ this.handleTourType(""); } } >All Tours</a>
						<a className="day_tours" onClick={ (e)=>{ window.location.href="/day-tours" } }>Day Tours</a>
						<a className="round_tours" onClick={ (e)=>{ window.location.href="/round-tours"  } }>Round Tours</a>
						{/* <a className="transport" onClick={ (e)=>{ this.handleTourType(4); } }>Transport</a> */}
					</div>
					<h4>Search Tours</h4>
					<div className="left-filte">
						<div className="travel_date">
							<h5>Travel Date & Duration</h5>

							<PrettoSlider
									valueLabelDisplay="auto"
									min={1}
        							max={14}
									aria-label="Duration slider"
									onChangeCommitted={(e, val)=>{this.handleChangeDuration(val)}}
									defaultValue={[this.state.durationMin, this.state.durationMax]} />


							<div className="day lf-day"><span>1</span> Day</div>
							<div className="day lr-day"><span>14</span> Days</div>
							<div className="clear"></div>
						</div>
						<div className="travel_date">
							<h5>Price Range</h5>

							<PrettoSlider
									valueLabelDisplay="auto"
									min={10}
        							max={1000}
									aria-label="Duration slider"
									defaultValue={[this.state.priceMin, this.state.priceMax]}
									onChangeCommitted={(e, val)=>{this.handleChangePrice(val)}}  />


							<div className="day lf-day"><span>10</span> USD</div>
							<div className="day lr-day"><span>1,000</span>USD</div>
							<div className="clear"></div>
						</div>

						<div className="travel_range catego">
							<h5 onClick={()=>this.setState({categoryOpen: this.state.categoryOpen === true ? false:true, otherOpen:false,locationOpen:false})}
								className={ this.state.categoryOpen === true ? 'open':''}
							>Categories</h5>
							<div>
								{ this.props.categories !== null  ? this.props.categories.map((cats,index)=>{
									return this.state.selecetedCategories.includes(cats.id.toString()) ? <Chip label={cats.name} size="small" style={{margin:2}} onDelete={()=>{this.removeCategory(cats.id.toString())}}  /> : null;
								}):null}
							</div>
							<form action="#" className={ this.state.categoryOpen === true ? 'open':''}>
							    <div className="input-field">
								<Select name="pickUpLocation"
                                            value={this.state.category}
                                            onChange={(e)=>this.handleCategory(e)}
                                            placeholder="Select category"
                                            options={this.props.categories}
                                            optionvalue="id"
                                            optionlabel="name"
                                            style={{width:'100%'}} />
								 </div>
							</form>
							<div className="clear"></div>
						</div>
						<div className="travel_range catego">
							<h5 onClick={()=>this.setState({locationOpen: this.state.locationOpen === true ? false:true, otherOpen:false,categoryOpen:false})}
								className={ this.state.locationOpen === true ? 'open':''}
								>Locations</h5>
							<div>
								{ this.props.locations !== null  ? this.props.locations.map((loc,index)=>{
									return this.state.selecetedLocations.includes(loc.value.toString()) ? <Chip label={loc.label} size="small" style={{margin:2}} onDelete={()=>{this.removeLocation(loc.value)}}  /> : null;
								}):null}
							</div>
							<form action="#" className={ this.state.locationOpen === true ? 'open':''}  >
							    <div className="input-field">
								<Select name="pickUpLocation"
                                            value={this.state.location}
                                            onChange={(e)=>this.handleLocations(e)}
                                            placeholder="Pick-up Location"
                                            options={this.props.locations}
                                            optionvalue="value"
                                            optionlabel="label"
                                            style={{width:'100%'}} />
								 </div>
							</form>
							<div className="clear"></div>
						</div>
						<div className="travel_range others">
							<h5 onClick={()=>this.setState({otherOpen: this.state.otherOpen === true ? false:true, locationOpen:false,categoryOpen:false})}
								className={ this.state.otherOpen === true ? 'open':''} >Others</h5>
							<form action="#" className={ this.state.otherOpen === true ? 'open':''} >
							    <p>
							      <label>
							        <input type="checkbox" checked={this.state.deals} onChange={this.handleDealChange} />
							        <span>Deals & Discounts</span>
							      </label>
							  	</p>
							</form>
							<div className="clear"></div>
						</div>
					</div>
					<div className="latest_deals">
						<h4> Get the Latest Deals <br /> & Discounts </h4>

						<input  value={this.state.email}  onChange={(e)=>{this.handleChange(e,'email')}} 							placeholder='Email Adderss' id="Emailaddress" type="email"  style ={{textcolor:'white'}} 							name=""/>
						{/*<input type="email" value="Email Address" name="" />*/}
							<input type="submit" onClick={()=>{ this.sentEmail(); }} value="submit" name="" />
							<div className="clear"></div>

					</div>
				</div>
        );
    }
}

SearchSideBar.defaultProps = {
    home: false,
  }


export default compose(connect(mapStateToProps, mapDispatchToProps))(SearchSideBar);
