import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { registerAccount } from '../Actions/WebsiteActions';
import Header from './Common/Header';
import Footer from './Common/Footer';
import SearchSideBar from './Common/SearchSideBar';
import RoundTourItinery from './Common/RoundTourItinery';
import DayTourItinery from './Common/DayTourItinery';
import PricingWidget from './Common/PricingWidget';
import { GetActivity, RelatedActivities } from '../Actions/ActivityActions';
import StarRatingComponent from 'react-star-rating-component';
import StarRatingComponent2 from 'react-rating-stars-component';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Helmet } from "react-helmet";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';


const mapStateToProps = (state) => {
	return {
		activeTour: state.Activities.activeTour,
		relatedTours: state.Activities.relatedTours,
		BookNow: state.Cart.bookNow,
	}
}

const mapDispatchToProps = dispatch => ({
	createAccount: (data, UserType) => dispatch(registerAccount(data, UserType)),
	GetActivity: (aid) => dispatch(GetActivity(aid)),
	RelatedActivities: (cats) => dispatch(RelatedActivities(cats))

});


const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const useStylesBootstrap = makeStyles(theme => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
	},
}));

const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: '#fff',
		color: '#f8a41b',
		//   maxWidth: 220,
		fontSize: theme.typography.pxToRem(14),
		border: '1px solid #f8a41b',
	},
}))(Tooltip);

class ActivityDetails extends Component {


	constructor(props) {
		super(props);

		const { aid } = props.match.params;

		this.state = {
			value: 0,
			relatedLoaded: false,
		}

		if (aid !== undefined) {
			this.props.GetActivity(aid);
		}
	}

	sliderREf;

	setSliderRef = ref => { this.sliderREf = ref };

	handleTabChange = (event, NewValue) => {
		this.setState({ value: NewValue });
	}

	handleUpdate = (event, field) => {
		this.setState({
			[field]: event.target.value
		})
	}

	doRegistration = () => {
		this.props.createAccount(this.state, 3);
	}

	getTripType(type) {

		switch (type) {
			case 1: { return "Round tour" }
			case 2: { return "Day Tour" }
			case 3: { return "Activity" }
		}
	}

	getDuration(measure) {
		switch (measure) {
			case 1: { return "Days" }
			case 2: { return "Hours" }
			case 3: { return "Minutes" }
		}
	}


	getStatus(status) {
		switch (status) {
			case 0: { return "Pending" }
			case 1: { return "Active" }
			case 2: { return "Rejected" }
		}
	}

	getPricingBasis(status) {

		switch (status) {
			case '1': { return "Per Person" }
			case '2': { return "Per Group" }
		}
	}

	componentDidUpdate() {

		if (this.props.activeTour.images !== null) {
			if (window.$.flexslider) {
				window.$(this.sliderREf).flexslider('destroy');
				window.$(this.sliderREf).flexslider({
					animation: "slide",
					controlNav: "thumbnails",
				});
			}
		}



		if (this.state.relatedLoaded === false) {

			this.setState({
				relatedLoaded: true
			})

			if (this.props.activeTour.category[0] !== undefined) {
				var cats = [];
				for (var x = 0; x < this.props.activeTour.category.length; x++) {
					cats.push(this.props.activeTour.category[x].category_id);
				}

				this.props.RelatedActivities(cats.join());
			}
		}


		if (this.props.BookNow === true) {
			window.location.href = '/checkout';
		}

	}

	handleAddBooking = () => {

	}


	BootstrapTooltip(props) {
		const classes = useStylesBootstrap();

		return <Tooltip arrow classes={classes} {...props} />;
	}

	render() {
		return <Fragment>
			<Header />

			<Helmet>
				<meta charSet="utf-8" />
				<title>{this.props.activeTour.title}</title>
				<meta name="description" content={this.props.seo_description} />
				<meta property="og:title" content={this.props.activeTour.title} />
				<meta property="og:type" content="article" />
				<meta property="og:url" content={this.props.activeTour.seo_url} />
				<meta property="og:image" content={this.props.activeTour.cover_image} />
			</Helmet>
			<section className="body-gry">
				<div className="container">
					<div className="row">
						<div className="col s12">
							<div className="bed-det">
								<a href="/">Home</a>  /   <a href="">{this.getTripType(this.props.activeTour.tour_type)}</a>  /  <span>{this.props.activeTour.title}</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col l3 m5 s12 ipad-none">
							<SearchSideBar location={this.props.location} />
						</div>

						<div className="col l6 m7 s12">
							<div className="tour-deit">

								<div className="dei-silder">
									<div className="flex-div">
										<div ref={this.setSliderRef}>
											<ul className="slides">

												{this.props.activeTour.images !== null ? this.props.activeTour.images.map((item) => {
													return <li key={item.id} data-thumb={item.url} >
														<img src={item.url} alt={item.alt_text} />
													</li>
												}) : null}


											</ul>
										</div>
										<div className="sid-rew">
											<div className="ratings">Supplier Ratings <span>0/10</span></div>
											<div className="reviews">Customer Reviews
												<span>
													<StarRatingComponent
														name="rate2"
														editing={false}
														starCount={5}
														value={this.props.activeTour.average_rating}
														renderStarIcon={() => <i style={{ fontSize: 20, fontStyle: 'normal' }} >★</i>}
													/>
												</span>
											</div>
										</div>
									</div>
									<div className="cont-sid">
										<div>
											<p><span>Tour ID</span>{this.props.activeTour.tour_id}</p>
											<p><span>Duration</span>{this.props.activeTour.duration_value}&nbsp;{this.getDuration(this.props.activeTour.duration)} </p>
										</div>
										<div className="text-right">

											<p><span>Selling Count</span>{this.props.activeTour.sold}</p>
											<p><span>Category</span>{this.props.activeTour.category[0] !== undefined ? this.props.activeTour.category[0].category.name : null} {this.props.activeTour.category.length > 1 ? (<em> <HtmlTooltip
												title={
													<React.Fragment>
														{this.props.activeTour.category.map((data, index) => {
															if (index !== 0) {
																return <p>{data.category.name}</p>
															}
														})}
													</React.Fragment>
												}
											>
												<em>+{this.props.activeTour.category.length - 1} more</em>
											</HtmlTooltip></em>) : null} </p>
										</div>
										<div className="clear"></div>
									</div>
								</div>

								<h1>{this.props.activeTour.title}</h1>

								<div className="tab-section">

									<div className="tab-li">
										<Tabs value={this.state.value} indicatorColor="primary" textColor="primary" className="tabs" onChange={this.handleTabChange} >
											<Tab label="itinerary" className="tab" />
											<Tab label="Includes" className="tab" />
											<Tab label="Excludes" className="tab" />
											<Tab label="Conditions" className="tab" />
											<Tab label="Know Before You Go" className="tab" />
										</Tabs>

										<div className="tab-cont">
											{this.state.value === 0 && <div className="col s12 tbc">


												<p>{this.props.activeTour.overview}</p>


												{this.props.activeTour.tour_type === 1 ? (
													<RoundTourItinery itinery={this.props.activeTour.itinery} />
												) : <DayTourItinery itinery={this.props.activeTour.itinery} />}</div>}

											{this.state.value === 1 && <div className="col s12 tbc">
												<ul>
													{this.props.activeTour.inclusion !== null ? this.props.activeTour.inclusion.map((item) => {
														return <li>{item}</li>
													}) : null}
												</ul>

											</div>}

											{this.state.value === 2 && <div className="col s12 tbc">
												<ul>
													{this.props.activeTour.exclision !== null ? this.props.activeTour.exclision.map((item) => {
														return <li>{item}</li>
													}) : null}
												</ul>

											</div>}
											{this.state.value === 3 && <div className="col s12 tbc">
												<ul>
													{this.props.activeTour.condtions !== null ? this.props.activeTour.condtions.map((item) => {
														return <li>{item}</li>
													}) : null}
												</ul>

												<br />
												<b style={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', color: '#f8a41b' }}>CANCELLATION POLICY</b>
												<p>{this.props.activeTour.cancel_policy}</p>
											</div>}
											{this.state.value === 4 && <div className="col s12 tbc">
												<ul>
													{this.props.activeTour.know_policy !== null ? this.props.activeTour.know_policy.map((item) => {
														return <li>{item}</li>
													}) : null}

												</ul>

											</div>}
											<div style={{ clear: 'both' }}></div>
										</div>
									</div>




								</div>
								{this.props.activeTour.clientReviews !== null && this.props.activeTour.clientReviews !== undefined && this.props.activeTour.clientReviews.length > 0 ? (

									<div class="review-div">
										<h4>Customer Reviews</h4>
										{this.props.activeTour.clientReviews.map((item) => <div class="review-person">
											<div class="rw-img">
												<span>
													{/*<img src="img/kane.png"/>*/}
												</span>
											</div>
											<div class="rw-cont">
												<div>
													<h6>{item.profile.first_name} {item.profile.last_name.substr(0, 1)} <span>{item.review_datetime}</span></h6>
													<div>
														<StarRatingComponent2
															name="rate2"
															editing={false}
															size={16}
															starCount={5}
															value={item.review_rating}
															emptyIcon={<span>☆</span>}
															halfIcon={<span>☆</span>}
															filledIcon={<span>★</span>}
															edit={false}
															color1={'#d6a230'}
															color2={'#d6a230'}
														/>
													</div>
												</div>
												<p>{item.review_feedback}</p>
											</div>
										</div>)}

									</div>

								) : null}

							</div>
						</div>

						<div className="col l3 m5 s12">
							{/* todo */}
							<PricingWidget activeTour={this.props.activeTour} />
							<div className="related_tours">
								<h3>Related Tours</h3>
								{this.props.relatedTours !== undefined && this.props.relatedTours !== null ? this.props.relatedTours.map((item, index) => {
									return <a href={'/activity/details/' + item.id}>
										<h4>{item.title.substring(0, 50)}</h4>
										<p>{item.overview.substring(0, 75)}...</p>
									</a>;
								}) : null}

							</div>
						</div>

					</div>
				</div>
			</section>

			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActivityDetails);
