import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import store from '../store';
export function startLoading(){
    return{type:'LOADIND_START'};
}

export function endLoading(){
    return{type:'LOADIND_END'};
}

export function SnackBar(show,message,varient){
    return {
                type:'SHOW_ALERT',
                show : show,
                message : message,
                varient:varient,
           } ;

}

export function ConfimEmail(show,data,action){
    return {
                type:'CONFIRM_EMAIL',
                show : show,
                data : data,
                onConfirm:action
           } ;

}

export function showInquire(show) {
    return {
        type: 'SHOW_INQUIRE',
        show: show,

    }
}

export function ShowLoadingSpinner(field,status) {
  return {
      type: 'SHOW_LOADER',
      field: field,
      status:status
  }
}

export function ShowAlert(show,message,varient){

  return function(dispatch){
      dispatch(SnackBar(show,message,varient));
  }

}

export function handleHTTPError(response){

  // if (response.status === 404) {
  //     window.location.href="/404";
  // }

    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

//  export function fetchInquare(name,email,phone,message){

//      return function (dispatch){
//         dispatch(startLoading());
//          fetch(API_URL+'/api/inquiry?name='+name+'&email='+email+'&phone='+phone+'&message='+message,{
//             method: 'GET',
//              headers: {
//               'Content-Type': 'application/json;charset=utf-8',
//             }
//         }).then(response => response.json(), error => console.log('An error occurred.', error))
//           .then((json) =>{
//                   dispatch(storeInquiry(json));
//                   dispatch(endLoading());
//           });
//    }
//  }

 export function fetchInquare(name,email,phone,message){

    return function(dispatch) {

          fetch(API_URL+'/api/email/inquiry',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            //   'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({name:name,email:email,phone:phone,message:message}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                //   dispatch(GetBooking(bookingID));
                  dispatch(SnackBar(true,'Message sent!','success'));
              });
         }).catch((error)=>{
           dispatch(endLoading());
          dispatch(SnackBar(true,'Could not sent request','error'));
        });
     }

 }


export function fetchEmail(email){

    return function(dispatch) {

          fetch(API_URL+'/api/email/sub',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            //   'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({email:email}),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());
                //   dispatch(GetBooking(bookingID));
                  dispatch(SnackBar(true,'Email address subscribed','success'));
              });
         }).catch((error)=>{
           dispatch(endLoading());
          dispatch(SnackBar(true,'Could not sent Request','error'));
        });
     }

 }




export function storeInquiry(inq){
    return{type:'STORE_INQUIRE',inquaries:inq};
}
