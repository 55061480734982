import React, { Props, Component, Fragment } from 'react';

import SearchResultLIstViewItem from './SearchResultLIstViewItem';
import CircularProgress from '@material-ui/core/CircularProgress';

import { compose } from 'redux';
import { connect } from 'react-redux';

import SearchResultListGridItem from './SearchResultListGridItem';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import loadingAnim from '../../Images/loading.gif';


const $ = window.jQuery;


const paginationItem = (reslts,callback)=>{
	var items = [];
	for(var x=1; x <= reslts.last_page; x++){
		items.push(<li className={reslts.current_page === x ? 'active':'waves-effect' }><a onClick={callback.bind(this,x)} href style={{cursor:'pointer'}}>{x}</a></li>)
	}

	return items;
}

const mapStateToProps = (state) => {
    return {
		searchResults:state.Activities.searchResults,
		normalSearchedItemsLoading:state.Common.normalSearchedItemsLoading,
		featuredSearchedItems:state.Activities.featuredSearchedItems
    }
  }

  const mapDispatchToProps = dispatch => ({

  });
class GridView extends Component {

	owlFeaturedTours = React.createRef();

    constructor(props){
		super(props)

		this.state = {

			faturedToursLoaded:false,
			carouselBuilt:false,
			updateView:false

		}


	}

	componentWillReceiveProps(){

	}

	componentDidMount = () =>{
		this.setState({
			updateView:true,
		});
	}

	componentDidUpdate = () =>{



		if(this.props.featuredSearchedItems !== null && this.state.faturedToursLoaded === false && this.state.carouselBuilt === false){
				var _this = this;
				setTimeout(()=>{

					this.setState({
						carouselBuilt:true,
						faturedToursLoaded:true
					});

					$(this.owlFeaturedTours.current).owlCarousel('destroy');
					this.carouel = $(this.owlFeaturedTours.current).owlCarousel({
					loop: false,
					responsiveClass: true,
					responsive: {
						0: {
							items: 1,
							nav: false,
							dote: true,
							loop: true,
							margin: 0
						},
						600: {
							items: 1,
							nav: false,
							dote: true,
							loop: true,
							margin: 10,
						},
						1000: {
							items: 3,
							loop: false,
							nav: false,
							dote: false,
							margin: 30,
						}
					}
				});

			}, 1500);



		}
	}


    render() {
				var featuredItems = 0;


        return (
            <div>
							  <div className="row">
									<div class="col l12 m12 s12">

											{this.state.faturedToursLoaded === true  ? (
												<div class="owl-carousel owl-theme owl-tour-scr rm-hid" ref={this.owlFeaturedTours}>
											{this.props.featuredSearchedItems !== null  ? this.props.featuredSearchedItems.map((item,index)=>{
												if(featuredItems > 15) return null;
												featuredItems++;
												return <SearchResultListGridItem searchParams={this.props.searchParams} data={item} key={"gv-"+index} />;
											}):null}

											</div>):null}




									</div>
								</div>
                <div className="row">
					<div className="col l12">
					{ this.props.normalSearchedItemsLoading === true ? (<div style={{width:'100%',minHeight:200,textAlign:'center'}} ><img src={loadingAnim} className="loading-animation"/></div>):null}
					{ this.props.normalSearchedItemsLoading === false && this.props.searchResults === null ? (<div style={{width:'100%',minHeight:200,textAlign:'center',paddingTop:100}} >Sorry, no experiences matching your search</div>):null}
					{ this.props.searchResults !== null && this.props.searchResults.data.length === 0  ? (<div style={{width:'100%',minHeight:200,textAlign:'center',paddingTop:100}} >Sorry, no experiences matching your search</div>):null}

						{this.props.searchResults !== null ? this.props.searchResults.data.map((item,index)=>{
							return <SearchResultLIstViewItem searchParams={this.props.searchParams} data={item} key={'lv-'+index} />;
						}):null}

					</div>
				</div>
				<div className="row">
					<div className="col s12">
						<div className="pagi">
						{this.props.searchResults !== null && this.props.searchResults.last_page > 1 ? (
							<ul className="pagination">
								{paginationItem(this.props.searchResults,this.props.onPageChange)}
							</ul>
						):null}

						</div>
					</div>
				</div>
            </div>
        );
    }
}




export default compose(connect(mapStateToProps, mapDispatchToProps))(GridView);
