import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { getReviewDetails, reviewMailUpdate } from '../Actions/WebsiteActions';
import StarRatingComponent from 'react-star-rating-component';
import { Redirect } from 'react-router-dom'
import { SnackBar } from '../Actions/CommonActions'
import Loader from "react-js-loader"
import "./style.css"

const mapStateToProps = (state) => {
    return {
        activeTour: state.Activities.activeTour,
        relatedTours: state.Activities.relatedTours,
        BookNow: state.Cart.bookNow,
        review: state.review
    }
}

const mapDispatchToProps = dispatch => ({
    getReviewDetails: (token) => dispatch(getReviewDetails(token)),
    SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
    reviewMailUpdate: (data) => dispatch(reviewMailUpdate(data))
});


class ReviewPage extends Component {


    constructor(props) {
        super(props);

        const { token } = this.props.match.params;

        this.state = {
            activity: null,
            value: 0,
            relatedLoaded: false,
            redirect: false,
            loading: false,
            rate: 0,
            feedback: "",
            token: token
        }

        if (token !== null && token !== undefined) {
            this.props.getReviewDetails(token);
        }

    }

    componentDidUpdate() {
        if (this.props.review.data != null && !this.props.review.data && !this.state.redirect) {
            this.setState({ redirect: true })
        }
        if (this.props.review.data == null && !this.state.redirect) {
            this.setState({ loading: true })
        }
        if (this.props.review.data != null && this.state.activity == null) {
            this.setState({ activity: this.props.review.data })
        }
    }

    rateCount = (count) => {
        this.setState({ rate: count })
    }
    handlechange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    AddReview = () => {
        console.log(this.state, "sajith State")
        if (this.state.rate == 0) {
            this.props.SnackBar(true, 'Rate your activity', 'error')
            return false
        } else if (this.state.feedback == "") {
            this.props.SnackBar(true, 'Write your review', 'error')
            return false
        }
        var data = this.state.activity
        this.props.reviewMailUpdate({
            user_id: data.booking.user_id,
            supplier_user_id: data.activity.user_id,
            activity_id: data.activity.id,
            booking_id: data.booking_id,
            review_rating: this.state.rate,
            review_feedback: this.state.feedback,
            token: this.state.token
        }
        )
    }
    render() {

        return <Fragment>
            <Header />
            <div className="review">
                {this.state.activity == null ?
                    <Loader type="ping-cube" bgColor="#f8a41b" color="#fff" title={"ping-cube"} size={350} /> : null}
                {this.state.activity !== null && this.state.activity !== false && !this.props.review.reviewSuccess ?
                    <div >
                        <div >
                            <div className="rw-img-pg">
                                {this.state.activity !== null && this.state.activity.activity_image !== null ? (
                                    <img src={this.state.activity.activity_image.name} />
                                ) : null}
                            </div>
                            <div className="rw-cont">
                                <h4>{this.state.activity !== null ? this.state.activity.activity.title : ""}</h4>
                                <p>{this.state.activity !== null ? this.state.activity.activity.overview.substr(0, 90) : ""}</p>
                            </div>
                        </div>
                        <div >
                            <h4>Booking Reference</h4>
                            {this.state.activity !== null ? this.state.activity.booking_ref : null}
                        </div>
                        <div>
                            <h4>Rate your activity </h4>
                            <div>
                                <StarRatingComponent
                                    name="rate2"
                                    starCount={5}
                                    value={this.state.rate}
                                    renderStarIcon={() => <i style={{ fontSize: 48, fontStyle: 'normal' }} >★</i>}
                                    onStarClick={(newValue) => { this.rateCount(newValue) }}
                                />
                            </div>
                            <div className="frm">
                                <div className='rw-text-area'>
                                    <textarea value={this.state.feedback} name="feedback" rows={3} id="rate" maxlength="200" onChange={this.handlechange} placeholder="Write your review...."></textarea>
                                    <span id="cont"></span>
                                </div>
                            </div>
                            <div className='rw-img-btn'>
                                <a onClick={this.AddReview} className="waves-effect waves-light btn modal-trigger"> Submit Review </a>
                            </div>
                        </div>
                    </div> : null}
                {this.props.review.reviewSuccess ? <h2 className="review-success">Review sucessfully added!</h2> : null}
            </div>
            {this.state.activity == false ? <Redirect to="/404"></Redirect> : null}
            <Footer />
        </Fragment>
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReviewPage);