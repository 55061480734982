import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { validateUser, LoginWithSocialMedia, SendNewPassword } from '../Actions/AuthActions';
import { SnackBar } from '../Actions/CommonActions';
import Alert from './Common/Alert';
import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { Helmet } from "react-helmet";

firebase.initializeApp({
	apiKey: "AIzaSyCJhe-ZeDV7ECE6CSkchNO87vLi6CHAoO0",
	authDomain: "aktivitar-aa10a.firebaseapp.com"
})

const mapStateToProps = (state) => {
	return {
		loading: state.Common.loading,
		loggedIn: state.Auth.loggedIn
	}
}

const mapDispatchToProps = dispatch => ({
	validateUser: (username, password) => dispatch(validateUser(username, password)),
	SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
	LoginWithSocialMedia: (user) => dispatch(LoginWithSocialMedia(user)),
	SendNewPassword: (username) => dispatch(SendNewPassword(username))
});
class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: "",
			loggedIn: false,
			loading: false,
			showForgotpassword: false
		};
	}


	uiConfig = {
		signInFlow: "popup",
		signInOptions: [
			firebase.auth.GoogleAuthProvider.PROVIDER_ID,
			firebase.auth.FacebookAuthProvider.PROVIDER_ID,
			firebase.auth.TwitterAuthProvider.PROVIDER_ID,
		],
		callbacks: {
			signInSuccess: () => false
		}
	}


	ValidateEmail = (mail) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return (true)
		}
		return (false)
	}


	handleChange = name => event => {
		this.setState({
			[name]: event.target.value,
		});

		if (name === 'username') {
			this.setState({
				showForgotpassword: this.ValidateEmail(event.target.value)
			});
		}
	};

	handleEnterKey = (e) => {
		if (e.key === 'Enter') {
			if (this.state.username.length == 0) {
				this.props.SnackBar(true, "We need your Email", 'error');
				return false;
			}

			if (this.state.password.length == 0) {
				this.props.SnackBar(true, "Password can not be empty", 'error');
			}
			this.props.validateUser(this.state.username, this.state.password);
		}
	}

	login() {
		console.log('erer');
		if (this.state.username.length == 0) {
			this.props.SnackBar(true, "We need your Email", 'error');
			return false;
		}

		if (this.state.password.length == 0) {
			this.props.SnackBar(true, "Password can not be empty", 'error');
		}

		this.props.validateUser(this.state.username, this.state.password);

	}

	componentDidMount() {

	}


	componentDidMount = () => {

		window.scrollTo(0, 0)

		firebase.auth().onAuthStateChanged(user => {
			if (user !== null && this.props.loggedIn === false) {
				this.props.LoginWithSocialMedia(user);
			}

		})
	}

	handleNewPassword = () => {
		this.props.SendNewPassword(this.state.username);
	}

	render() {
		return <Fragment>
			<Header home={true} />
			<Helmet>
				<meta charSet="utf-8" />
				<title>Account Login</title>
			</Helmet>
			<Alert />
			<section className="gry-log">
				<div className="row">
					<div className="container">
						<div className="col l12 m12 s12">
							<div className="log-head">
								<h1>Log in to Aktivitar</h1>
								<p>Log in to add things to your wishlist and access your bookings from any device</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="cont-log">
				<div className="row">
					<div className="container">
						<div className="col l12 m12 s12">
							<div className="log-head">

								<div className="inp">
									<div style={{ display: "flex" }}    ><label>Email Address</label>   </div>

									<input type="text"
										required
										value={this.state.username}
										onChange={this.handleChange('username')}
										onKeyPress={(e) => this.handleEnterKey(e)}
									/>

								</div>
								<div className="inp">
									<div style={{ display: "flex" }} ><label>Password</label>  </div>

									<input type="password"
										required
										value={this.state.password}
										onChange={this.handleChange('password')}
										onKeyPress={(e) => this.handleEnterKey(e)}
									/>
								</div>
								{this.state.showForgotpassword === true ? (<div className="forgot">
									<a style={{ cursor: 'pointer' }} onClick={() => this.handleNewPassword()} >Send me new password</a>
								</div>) : (<div className="forgot">
									<a >Forgot your password ?, please fill in your email</a>
								</div>)}
								<div className="log-btn">
									<i className="waves-effect waves-light btn">
										<input type="submit" value="Login" style={{ cursor: 'pointer' }} onKeyPress={(e) => this.handleEnterKey(e)} onClick={() => { this.login(); }} />
									</i>

								</div>
								<div className="sinwith">
									<p> Or Sign in with </p>
									<div>
										<StyledFirebaseAuth
											uiConfig={this.uiConfig}
											firebaseAuth={firebase.auth()}
										/>

										<div className="clear"></div>
									</div>
								</div>
								<div className="hv-acunt">
									<p>Don't have an account yet? <Link to={'/account/signup'}>Sign Up</Link></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{this.props.loggedIn ? <Redirect to="/account"></Redirect> : null}
			<Footer />

		</Fragment>
	}
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);